import './index.scss'

import { useRef, useState } from 'react'
import API from '../../API'
import Lang from '../../Lang'
import Page from '../../Components/Page'
import { Button, FormGroup, InputGroup } from '@blueprintjs/core'

import logoUrl from '../../Assets/logo.png'
import mp4Video from 'url:../../Assets/login-video.mp4'
import webmVideo from 'url:../../Assets/login-video.webm'
import LanguageSelector from '../../Components/LanguageSelector'

export default function LoginPage() {
	const [busy, setBusy] = useState(false)

	const loginRef = useRef('')
	const passwordRef = useRef('')

	async function submit(e) {
		e.preventDefault()

		setBusy(true)

		let res = await API.call('authorize', {
			email: loginRef.current.value,
			password: passwordRef.current.value
		})

		if (res.user) {
			localStorage.setItem('sessionId', res.sessionId)
			location.reload()
		} else {
			setBusy(false)
			alert(Lang.get('Invalid login or password'))
		}
	}

	return (
		<Page title="Login" className="LoginPage bp5-dark" fullscreen>
			<video playsInline autoPlay muted loop controls={false}>
				<source src={mp4Video} type="video/mp4" />
				<source src={webmVideo} type="video/webm" />
			</video>
			<form onSubmit={submit}>
				<div className="LogoContainer">
					<img src={logoUrl} className="Logo" />
				</div>
				<div className="InputContainer">
					<FormGroup label={Lang.get('E-mail')} labelFor="loginInput">
						<InputGroup id="loginInput" inputRef={loginRef} autoFocus />
					</FormGroup>

					<FormGroup label={Lang.get('Password')} labelFor="passwordInput">
						<InputGroup id="passwordInput" inputRef={passwordRef} type="password" />
					</FormGroup>

					<FormGroup>
						<Button fill type="submit" intent="primary" loading={busy}>
							{Lang.get('Continue')}
						</Button>
					</FormGroup>

					<LanguageSelector />
				</div>
			</form>
		</Page>
	)
}
