import './index.scss'

import React, { useCallback, useEffect, useRef, useState } from 'react'
import API from '../../API'
import Lang from '../../Lang'
import Map from '../../Components/Map'
import Page from '../../Components/Page'
import DeviceList from './Components/DeviceList'
import mapboxgl, { LngLatBounds } from 'mapbox-gl'
import DeviceDetails from './Components/DeviceDetails'
import Placeholder from '../../Components/Placeholder'
import ProfileDetails from './Components/ProfileDetails'
import { Button, Menu, MenuItem, MenuDivider, ContextMenuPopover } from '@blueprintjs/core'

import { profileTemplate } from './Components/ProfileDetails/ProfileV2Editor'
import { isDeviceGateway, valueToHexColor } from '../../Util'
import { useMemo } from 'react'

export default function DevicesPage() {
	const [devices, setDevices] = useState(null)
	const [profiles, setProfiles] = useState(null)

	const [contextMenuDeviceId, setContextMenuDeviceId] = useState(null)
	const [contextMenuPosition, setContextMenuPosition] = useState({ left: 0, top: 0 })
	const [contextMenuOpen, setContextMenuOpen] = useState(false)

	const [selectedDevice, setSelectedDevice] = useState(null)
	const [selectedProfile, setSelectedProfile] = useState(null)
	const [searchingFor, setSearchingFor] = useState('')
	const [sidebarOpen, setSidebarOpen] = useState(false)

	const mapRef = useRef()

	function updateAllMarkerColors(iconColors) {
		const now = new Date().getTime() / 1000

		setDevices((curDevices) => {
			for (let d of curDevices) {
				if (!d.marker) continue

				const markerElement = d.marker.getElement()
				markerElement.style.backgroundColor = 'gray'

				if (iconColors == 'lighting-profile') {
					setProfiles((curProfiles) => {
						for (let p of curProfiles) {
							if (p.deviceIds.indexOf(d.id) != -1) {
								markerElement.style.backgroundColor = p.color
							}
						}
						return curProfiles
					})
				} else if (iconColors == 'device-status') {
					const diff = now - d.lastDataTime
					if (diff < 60 * 15) {
						// 15 minutes
						markerElement.style.backgroundColor = '#32A467'
					} else if (diff < 60 * 60 * 24) {
						// 24 hours
						markerElement.style.backgroundColor = '#EC9A3C'
					} else {
						markerElement.style.backgroundColor = '#CD4246'
					}
				} else if (iconColors == 'report-health') {
					const reportCount = d.reportHealth
					const healthPercentage = reportCount / 30
					markerElement.style.backgroundColor = valueToHexColor(healthPercentage)
				} else if (iconColors == 'operative') {
					const diff = now - d.lastDataTime
					if (diff >= 60 * 60) {
						// No data for an hour or more -> gray
						markerElement.style.backgroundColor = '#CCCCCC'
					} else {
						const d4i = d.lastCommands.find((c) => c.commandName == 'D4I')
						if (d4i?.payload.ledCurrent > 0) {
							// Led current positive -> green
							markerElement.style.backgroundColor = '#32A467'
						} else {
							// Led current zero, or no D4I command -> yellow
							markerElement.style.backgroundColor = '#CCCC66'
						}
					}
				}
			}

			return curDevices
		})
	}

	async function setDeviceProfile(dev, newProfile) {
		// Ignore if profiles isn't changing
		if (dev.lightingProfileId == newProfile.id) {
			console.info('Device already has this profile')
			return
		}

		const res = await API.call('LightingProfile.AddDevice', { deviceId: dev.id, profileId: newProfile.id })
		if (!res || !res.success) {
			console.error('Failed to add device to profile', res)
			return
		}

		// Remove device from current profile
		if (dev.lightingProfileId) {
			setProfiles((profiles) => {
				return profiles.map((profile) => {
					if (profile.id === dev.lightingProfileId) {
						return {
							...profile,
							deviceIds: profile.deviceIds.filter((id) => id != dev.id)
						}
					}
					return profile
				})
			})
		}

		// Add new profile to device
		setDevices((devices) => {
			return devices.map((device) => {
				if (device.id === dev.id) {
					return {
						...device,
						lightingProfileId: newProfile.id
					}
				}

				return device
			})
		})

		// Add device to new profile
		setProfiles((profiles) => {
			return profiles.map((profile) => {
				if (profile.id === newProfile.id) {
					return {
						...profile,
						deviceIds: profile.deviceIds.concat(dev.id)
					}
				}

				return profile
			})
		})

		updateAllMarkerColors('lighting-profile')
	}

	const contextMenuContent = useMemo(() => {
		if (!devices) return null

		const contextMenuDevice = devices.find((d) => d.id == contextMenuDeviceId)
		if (!contextMenuDevice) return null

		const neighbourValue = selectedDevice?.neighbours.find((n) => n.id == contextMenuDevice.id)

		return (
			<Menu>
				<MenuItem active={true} text={Lang.get('Device') + ' ' + contextMenuDevice.id} shouldDismissPopover={false} />

				{selectedDevice && selectedDevice.id != contextMenuDevice.id && (
					<>
						<MenuDivider />

						{neighbourValue ? (
							<MenuItem
								icon="delete"
								text={Lang.get('Remove Neighbour')}
								onClick={() => setNeighbourMultiplier(selectedDevice, contextMenuDevice, 0)}
							/>
						) : (
							<MenuItem
								icon="add"
								text={Lang.get('Add Neighbour')}
								onClick={() => setNeighbourMultiplier(selectedDevice, contextMenuDevice, 1)}
							/>
						)}
					</>
				)}

				<MenuDivider />

				{profiles?.length > 0 &&
					profiles
						.filter((p) => !p.disabled)
						.map((p) => {
							const selected = contextMenuDevice?.lightingProfileId == p.id

							return (
								<MenuItem
									shouldDismissPopover={false}
									key={`cm-p-${p.id}`}
									icon={selected ? 'tick' : 'blank'}
									text={p.title}
									onClick={() => setDeviceProfile(contextMenuDevice, p)}
								/>
							)
						})}
			</Menu>
		)
	}, [contextMenuDeviceId, profiles, devices, selectedDevice])

	function createMarkerElementForDevice(dev) {
		const markerElement = document.createElement('div')
		markerElement.className = 'Marker DeviceMarker'

		const iconElement = document.createElement('i')
		let deviceIcon = 'lightbulb'
		if (dev.customIcon) {
			deviceIcon = dev.customIcon
		}
		iconElement.className = 'fa-fw fa-duotone fa-' + deviceIcon
		markerElement.appendChild(iconElement)

		const labelElement = document.createElement('div')
		labelElement.className = 'label'
		labelElement.innerText = dev.id
		markerElement.appendChild(labelElement)

		markerElement.style.backgroundColor = 'gray'

		if (isDeviceGateway(dev)) {
			const gatewayIcon = document.createElement('i')
			gatewayIcon.className = 'gatewayIcon fa-fw fa-duotone fa-wifi'
			markerElement.appendChild(gatewayIcon)
		}

		// Right click handler
		markerElement.addEventListener('contextmenu', (e) => {
			e.preventDefault()

			setContextMenuPosition({ left: e.clientX, top: e.clientY })
			setContextMenuOpen(true)
			setContextMenuDeviceId(dev.id)
		})

		return markerElement
	}

	// updateNeighbourIndicators
	useEffect(() => {
		if (!selectedDevice) {
			if (mapRef.current.getLayer('neighbour-lines-layer')) {
				mapRef.current.removeLayer('neighbour-lines-layer')
			}
			if (mapRef.current.getSource('neighbour-lines')) {
				mapRef.current.removeSource('neighbour-lines')
			}
			return
		}

		const linesData = {
			type: 'FeatureCollection',
			features: []
		}

		for (let n of selectedDevice.neighbours) {
			const neighbour = devices.find((d) => d.id == n.id)
			if (!neighbour) continue
			if (!neighbour.latitude || !neighbour.longitude) continue

			linesData.features.push({
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: [
						[selectedDevice.longitude, selectedDevice.latitude],
						[neighbour.longitude, neighbour.latitude]
					]
				}
			})
		}

		const source = mapRef.current.getSource('neighbour-lines')
		if (source) {
			source.setData(linesData)
			return
		}

		mapRef.current.addSource('neighbour-lines', {
			type: 'geojson',
			data: linesData
		})

		mapRef.current.addLayer({
			type: 'line',
			source: 'neighbour-lines',
			id: 'neighbour-lines-layer',
			paint: {
				'line-color': '#ff6600',
				'line-width': 4,
				'line-opacity': 0.6
			}
		})
	}, [selectedDevice, devices])

	async function setNeighbourMultiplier(device, neighbour, multiplier) {
		const existing = device.neighbours.find((n) => n.id == neighbour.id)

		const wantsAdd = !existing && multiplier > 0
		const wantsRemove = existing && multiplier == 0

		if (wantsAdd || wantsRemove) {
			setDevices((devices) => {
				return devices.map((dev) => {
					if (dev.id == device.id) {
						const newDevice = {
							...dev,
							...(wantsAdd && { neighbours: [...dev.neighbours, { id: neighbour.id, multiplier }] }),
							...(wantsRemove && { neighbours: dev.neighbours.filter((n) => n.id != neighbour.id) })
						}

						console.log({
							oldDevice: {
								id: dev.id,
								neighbours: dev.neighbours
							},
							newDevice: {
								newDevice: newDevice.id,
								neighbours: newDevice.neighbours
							}
						})

						device = newDevice
						setSelectedDevice(newDevice)
						selectDevice(newDevice)
						return newDevice
					}
					return dev
				})
			})
		}

		let newNeighbours = device.neighbours.map((n) => ({ id: n.id, multiplier: n.multiplier }))

		await API.call('Device.SetNeighbours', { deviceId: device.id, neighbours: newNeighbours })
	}

	function zoomDevices(devices) {
		const zoomBounds = new LngLatBounds()
		for (let d of devices) {
			if (!d.latitude || !d.longitude) continue

			zoomBounds.extend([d.longitude, d.latitude])
		}

		if (Object.values(zoomBounds).length === 0) return

		mapRef.current.fitBounds(zoomBounds, { padding: 50, duration: 250 })
	}

	function selectDevice(device) {
		setSelectedDevice((curDevice) => {
			if (device === curDevice) return curDevice

			if (curDevice) {
				curDevice.marker?.getElement().classList.remove('selected')
			}

			if (device) {
				device.marker?.getElement().classList.add('selected')
				setSelectedProfile(null)
			}

			return device
		})
	}

	function selectProfile(profile) {
		setSelectedProfile(profile)
		selectDevice(null)
	}

	useEffect(() => {
		mapRef.current?.resize()

		// center selected device after map resize
		if (selectedDevice) {
			const curZoom = mapRef.current.getZoom()
			const targetZoom = 15

			const coords = [selectedDevice.longitude, selectedDevice.latitude]

			if (!coords.every((c) => c)) return

			const flyToOptions = {
				center: coords,
				...(curZoom < targetZoom && { zoom: targetZoom }),
				essential: true
			}
			mapRef.current.flyTo(flyToOptions)
		}
	}, [selectedDevice, selectedProfile, sidebarOpen])

	const selectDeviceFromMarker = useCallback(
		(deviceId) => {
			setDevices((devices) => {
				const device = devices.find((d) => d.id == deviceId)
				selectDevice(device)
				return devices
			})
		},
		[devices]
	)

	async function hydrate(zoom = true) {
		let [devices, profiles] = await Promise.all([API.getDevices(), API.getProfiles()])

		console.log(devices)

		devices = devices.devices ?? []

		setDevices(devices)
		setProfiles(profiles)
		setSidebarOpen(true)

		for (let d of devices) {
			if (!d.latitude || !d.longitude) continue

			const markerElement = createMarkerElementForDevice(d)
			if (!mapRef.current) continue

			try {
				const marker = new mapboxgl.Marker({ element: markerElement }).setLngLat([d.longitude, d.latitude]).addTo(mapRef.current)
				markerElement.addEventListener('click', (e) => selectDeviceFromMarker(d.id))
				d.marker = marker
			} catch (e) {
				// pofig
			}
		}

		zoomDevices(devices)
		updateAllMarkerColors('lighting-profile')
	}

	function onMapViewStateChange(newState) {
		if (newState.iconColors) {
			updateAllMarkerColors(newState.iconColors)
		}
	}

	function wantsNewProfile(args) {
		let newProfile = {
			id: 0,
			title: Lang.get('New Profile'),
			daliSpeed: 1,
			motionTime: 60,
			deviceIds: []
		}

		if (args.version == 1) {
			newProfile = {
				...newProfile,
				version: 1,
				sunriseOffset: 0,
				sunsetOffset: 0,
				dayBrightness: 0,
				nightBrightness: 70,
				nightBrightnessMotion: 100,
				nightBrightnessN1: 90,
				nightBrightnessN2: 80,
				nightBrightnessN3: 0,
				nightBrightnessN4: 0,
				nightBrightnessN5: 0
			}
		} else if (args.version == 2) {
			newProfile = {
				...newProfile,
				version: 2,
				title: Lang.get('New Profile'),
				...JSON.parse(JSON.stringify(profileTemplate))
			}
		}

		selectProfile(newProfile)
	}

	return (
		<Page title={Lang.get('Devices')} icon="fa-duotone fa-router" className={'DevicesPage'} noPadding>
			<Placeholder overlay overlayVisible={devices == null} loading label={Lang.get('Loading devices') + ' ...'} />

			<div className={`container sideBar ${sidebarOpen && 'open'}`}>
				<div className="topBar">
					<SearchInput value={searchingFor} onChange={(e) => setSearchingFor(e.target.value)} />
					<Button
						// text={Lang.get('Create New Profile')}
						icon="add"
						minimal
						intent="primary"
						onClick={() => wantsNewProfile({ version: 2 })}
					/>
				</div>
				{devices && profiles && (
					<DeviceList
						devices={devices}
						lightProfiles={profiles}
						searchingFor={searchingFor}
						selectedProfile={selectedProfile}
						selectedDevice={selectedDevice}
						selectProfile={selectProfile}
						selectDevice={selectDevice}
					/>
				)}
			</div>

			<div className={`container fakeSideBar ${sidebarOpen && 'open'}`}></div>

			<DeviceDetails
				device={selectedDevice}
				view={{
					selectDevice,
					zoomDevices
				}}
			/>

			{selectedProfile && <ProfileDetails profile={selectedProfile} selectProfile={selectProfile} reload={hydrate} />}
			<div className="MapContainer">
				{/* <Button
					className="sidebarControl"
					icon={sidebarOpen ? 'caret-left' : 'caret-right'}
					intent="primary"
					onClick={toggleSidebar}
				/> */}

				<ContextMenuPopover
					isOpen={contextMenuOpen}
					onClose={() => setContextMenuOpen(false)}
					targetOffset={contextMenuPosition}
					content={contextMenuContent}
				/>

				<Map
					onReady={(ref) => {
						mapRef.current = ref.current
						hydrate()
					}}
					onViewStateChange={(newState) => onMapViewStateChange(newState)}
				/>
			</div>
		</Page>
	)
}

function SearchInput(props) {
	return (
		<div className="SearchInput">
			<i className="fa-fw fa-duotone fa-search" />
			<input type="text" placeholder={Lang.get('Search...')} value={props.value} onChange={props.onChange} />
		</div>
	)
}
