import React, { useEffect, useMemo, useRef } from 'react'
import { DateTime } from 'luxon'
import Lang from '../../../../../Lang'
import { DateInput2, TimePicker } from '@blueprintjs/datetime2'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { Button, ButtonGroup, InputGroup, Switch, Menu, MenuItem, NumericInput } from '@blueprintjs/core'
import { start } from 'repl'
import { dateToSecondsToday, secondsTodayToDate } from '../../../../../Util'

// ===================================================================

const conditionTypes = [
	{
		type: 'weekdays',
		label: Lang.get('Weekdays'),
		icon: 'fa-calendar-clock',
		incompatibleWith: ['weekdays'],
		defaultValue: []
	},
	{
		type: 'nightlight',
		label: Lang.get('Nightlight'),
		icon: 'fa-moon',
		incompatibleWith: ['nightlight', 'daylight'],
		defaultValue: { sunsetOffset: 0, sunriseOffset: 0 }
	},
	{
		type: 'daylight',
		label: Lang.get('Daylight'),
		icon: 'fa-sun',
		incompatibleWith: ['nightlight', 'daylight'],
		defaultValue: { sunsetOffset: 0, sunriseOffset: 0 }
	},
	{
		type: 'date',
		label: Lang.get('Date'),
		icon: 'fa-calendar',
		incompatibleWith: ['date'],
		defaultValue: DateTime.now().toFormat('yyyy-LL-dd')
	}
]

const MIN_PERIOD_DURATION = 900

// ===================================================================

export default function ConditionBlock({ lightingProfileId, blockData, blockAction, sunriseSunsetTimes }) {
	function addCondition(conditionType) {
		const condition = conditionTypes.find((c) => c.type == conditionType)
		if (!condition) {
			return
		}
		if (condition.incompatibleWith.some((c) => blockData.conditions[c])) {
			alert(Lang.get('This condition is incompatible with existing conditions'))
			return
		}

		blockAction('set-value', { conditions: { ...blockData.conditions, [condition.type]: condition.defaultValue } })
	}

	function removeCondition(conditionType) {
		const newConditions = { ...blockData.conditions }
		delete newConditions[conditionType]
		blockAction('set-value', { conditions: newConditions })
	}

	function setPeriodValue(index, key, value) {
		const cp = blockData.brightnessLevels

		const prevBlock = cp[index - 1]
		const curBlock = cp[index]
		const nextBlock = cp[index + 1]

		if (!curBlock) return // this should never happen

		switch (key) {
			case 'start': {
				// limit
				if (value > (nextBlock?.start ?? 86400) - MIN_PERIOD_DURATION) value = (nextBlock?.start ?? 86400) - MIN_PERIOD_DURATION
				if (value < prevBlock.start + MIN_PERIOD_DURATION) {
					value = prevBlock.start + MIN_PERIOD_DURATION
				}

				const newStart = value
				curBlock.start = newStart

				const newCurDuration = (nextBlock?.start ?? 86400) - newStart
				curBlock.duration = newCurDuration

				const newPrevDuration = newStart - prevBlock.start
				prevBlock.duration = newPrevDuration

				break
			}

			// idleBrightness <= activeBrightness
			case 'idleBrightness': {
				if (value > curBlock.activeBrightness) {
					curBlock.activeBrightness = value
				}
				curBlock.idleBrightness = value

				break
			}

			// activeBrightness >= idleBrightness
			case 'activeBrightness': {
				if (value < curBlock.idleBrightness) {
					curBlock.idleBrightness = value
				}
				curBlock.activeBrightness = value

				break
			}
			default: {
				curBlock[key] = value

				break
			}
		}

		blockAction('set-value', { brightnessLevels: cp })
	}

	useEffect(() => {
		const conds = { ...blockData.conditions }
		let brightnessLevels = [...blockData.brightnessLevels.map((p) => ({ ...p }))]
		let { sunrise, sunset } = sunriseSunsetTimes

		if (conds.nightlight || conds.daylight) {
			// sunrise += conds.nightlight.sunriseOffset
			// sunset += conds.nightlight.sunsetOffset

			const hasSetFakeBlocks = brightnessLevels.some((period) => period.fake)
			if (hasSetFakeBlocks) {
				// update the fake blocks based on sunrise/sunset offsets
				if (conds.nightlight) {
					// only 1 block
					sunrise += conds.nightlight.sunriseOffset * 60
					sunset += conds.nightlight.sunsetOffset * 60

					if (sunrise > sunset || sunset < sunrise) return

					const idx = brightnessLevels.findIndex((period) => period.fake)

					const cur = brightnessLevels[idx]
					const prev = brightnessLevels[idx - 1]
					const next = brightnessLevels[idx + 1]
					const nextNext = brightnessLevels[idx + 2]

					cur.start = sunrise
					cur.duration = sunset - sunrise

					const newPrevDuration = sunrise - prev.start
					if (newPrevDuration < 600) {
						deletePeriod(idx - 1)
					} else {
						prev.duration = newPrevDuration
					}

					next.start = sunset

					const newNextDuration = (nextNext?.start ?? 86400) - sunset
					if (newNextDuration < 600) {
						deletePeriod(idx + 1)
					} else {
						next.duration = newNextDuration
					}

					blockAction('set-value', { brightnessLevels })
				}

				if (conds.daylight) {
					sunrise += conds.daylight.sunriseOffset * 60
					sunset += conds.daylight.sunsetOffset * 60

					if (sunrise > sunset || sunset < sunrise) return

					const first = brightnessLevels[0]
					const last = brightnessLevels[brightnessLevels.length - 1]

					first.duration = sunrise
					last.start = sunset
					last.duration = 86400 - sunset

					// adjust everything inbetween
					brightnessLevels.forEach((period, idx) => {
						if (idx == 0 || idx == brightnessLevels.length - 1) return

						const next = brightnessLevels[idx + 1]
						const prev = brightnessLevels[idx - 1]

						if (prev.start + prev.duration != period.start) {
							const newStart = Math.max(prev.start + prev.duration, 600)
							const newDuration = next.start - period.start

							if (newDuration < 600 && !(idx == 1 && brightnessLevels.length == 3)) {
								deletePeriod(idx)
							} else {
								period.start = newStart
								period.duration = Math.max(newDuration, 600)
							}
						}

						if (period.start + period.duration != next.start) {
							const newDuration = next.start - period.start

							if (newDuration < 600 && !(idx == 1 && brightnessLevels.length == 3)) {
								deletePeriod(idx)
							} else {
								period.duration = Math.max(newDuration, 600)
							}
						}
					})

					blockAction('set-value', { brightnessLevels })
				}

				return
			}

			if (conds.nightlight) {
				sunrise += conds.nightlight.sunriseOffset * 60
				sunset += conds.nightlight.sunsetOffset * 60

				const nightBlock = {
					start: sunrise,
					duration: sunset - sunrise,
					idleBrightness: 0,
					activeBrightness: 0,
					fake: true
				}

				brightnessLevels.forEach((period) => {
					if (period.start >= sunrise && period.start + period.duration <= sunset) {
						period.duration = 0
					} else if (period.start < sunrise && period.start + period.duration > sunrise) {
						period.duration = sunrise - period.start
					} else if (period.start < sunset && period.start + period.duration > sunset) {
						const newStart = sunset
						period.duration = period.start + period.duration - sunset
						period.start = newStart
					}
				})

				brightnessLevels = brightnessLevels.filter((period) => period.duration > 0).sort((a, b) => a.start - b.start)

				const insertIndex = brightnessLevels.findIndex((period) => period.start > sunrise)
				const spliceIndex = insertIndex !== -1 ? insertIndex : brightnessLevels.length
				brightnessLevels.splice(spliceIndex, 0, nightBlock)

				const fillIndex =
					brightnessLevels.findIndex((period, idx) => {
						const nextPeriod = brightnessLevels[idx + 1]
						return period.start + period.duration < (nextPeriod?.start ?? 86400)
					}) + 1

				if (fillIndex > 0) {
					const sourcePeriod = brightnessLevels[fillIndex - 2]
					const fakePeriod = brightnessLevels[fillIndex - 1]
					const nextPeriod = brightnessLevels[fillIndex]

					const fillPeriod = {
						...sourcePeriod,
						start: fakePeriod.start + fakePeriod.duration,
						duration: (nextPeriod?.start ?? 86400) - (fakePeriod.start + fakePeriod.duration)
					}

					brightnessLevels.splice(fillIndex, 0, fillPeriod)
				}
			}

			if (conds.daylight) {
				sunrise += conds.daylight.sunriseOffset * 60
				sunset += conds.daylight.sunsetOffset * 60

				const morningBlock = {
					start: 0,
					duration: sunrise,
					idleBrightness: 0,
					activeBrightness: 0,
					fake: true
				}

				const eveningBlock = {
					start: sunset,
					duration: 86400 - sunset,
					idleBrightness: 0,
					activeBrightness: 0,
					fake: true
				}

				brightnessLevels.forEach((period, idx) => {
					if (period.start < sunrise) {
						period.start = sunrise

						const next = brightnessLevels[idx + 1]

						period.duration = Math.min(period.duration, sunset - sunrise, (next?.start ?? 86400) - sunrise)
					}
					if (period.start + period.duration > sunset) {
						period.duration = sunset - period.start
					}
				})

				brightnessLevels = brightnessLevels.filter((period) => period.duration > 0).sort((a, b) => a.start - b.start)

				brightnessLevels.unshift(morningBlock)
				brightnessLevels.push(eveningBlock)
			}

			blockAction('set-value', { brightnessLevels })
		} else {
			const fakeIndexes = brightnessLevels.reduce((acc, period, idx) => {
				if (period.fake) return [...acc, idx]

				return acc
			}, [])

			deletePeriods(fakeIndexes)

			// blockAction('set-value', { brightnessLevels: brightnessLevels.map((p) => ({ ...p, fake: false })) })
		}
	}, [lightingProfileId, JSON.stringify(blockData)])

	function splitPeriod(periodIndex) {
		const periods = blockData.brightnessLevels

		const period = periods[periodIndex]
		const newPeriod = {
			start: period.start + Math.round(period.duration / 2),
			duration: Math.round(period.duration / 2),
			idleBrightness: period.idleBrightness,
			activeBrightness: period.activeBrightness
		}
		periods.splice(periodIndex + 1, 0, newPeriod)
		period.duration = Math.round(period.duration / 2)
		blockAction('set-value', { brightnessLevels: periods })
	}

	function deletePeriods(indexArray) {
		const periods = blockData.brightnessLevels

		// Sort indexes in descending order to avoid shifting index issues
		indexArray.sort((a, b) => b - a)

		indexArray.forEach((index) => {
			const targetPeriod = periods[index]
			const prevPeriod = periods[index - 1]
			const nextPeriod = periods[index + 1]

			if (nextPeriod) {
				if (!nextPeriod.fake) {
					nextPeriod.start = targetPeriod.start
					nextPeriod.duration += targetPeriod.duration
					periods.splice(index, 1)
				} else {
					if (prevPeriod) {
						prevPeriod.duration += targetPeriod.duration
						periods.splice(index, 1)
					}
				}
			} else {
				if (prevPeriod) {
					prevPeriod.duration += targetPeriod.duration
					periods.splice(index, 1)
				}
			}
		})

		blockAction('set-value', { brightnessLevels: periods })
	}

	function deletePeriod(periodIndex) {
		const periods = blockData.brightnessLevels

		const targetPeriod = periods[periodIndex]
		if (targetPeriod.fake) {
			const conds = { ...blockData.conditions }

			if (conds.nightlight) {
				removeCondition('nightlight')
			} else if (conds.daylight) {
				removeCondition('daylight')
			}
			return
		}

		const prevPeriod = periods[periodIndex - 1]
		const nextPeriod = periods[periodIndex + 1]

		if (nextPeriod) {
			if (!nextPeriod.fake) {
				nextPeriod.start = targetPeriod.start
				nextPeriod.duration += targetPeriod.duration
				periods.splice(periodIndex, 1)
			} else {
				if (prevPeriod) {
					prevPeriod.duration += targetPeriod.duration
					periods.splice(periodIndex, 1)
				}
			}
		} else {
			if (!prevPeriod.fake) {
				prevPeriod.duration += targetPeriod.duration
				periods.splice(periodIndex, 1)
			}
		}

		blockAction('set-value', { brightnessLevels: periods })
	}

	const menu = (
		<Menu>
			{conditionTypes.map((c, i) => (
				<MenuItem
					key={`cbct-${c.label}${i}`}
					icon={<i className={`fa-fw fa-solid ${c.icon}`} />}
					onClick={() => addCondition(c.type)}
					text={c.label}
				/>
			))}
		</Menu>
	)

	const hasConditions = Object.keys(blockData.conditions).length > 0

	return (
		<div className="ConditionBlock">
			<div className="header">
				<div className="padded">
					<Switch
						label={Lang.get('Active')}
						checked={blockData.active}
						onChange={(e) => blockAction('set-value', { active: e.target.checked })}
					/>
					<InputGroup
						small
						placeholder={Lang.get('Note')}
						value={blockData.comment}
						onChange={(e) => blockAction('set-value', { comment: e.target.value })}
					/>
					<div>
						<ButtonGroup>
							<Tooltip2 content={Lang.get('Duplicate')}>
								<Button small minimal icon={<i className="fa-light fa-clone"></i>} onClick={() => blockAction('duplicate')} />
							</Tooltip2>
							<Button small minimal icon={<i className="fa-solid fa-caret-up"></i>} onClick={() => blockAction('move-up')} />
							<Button small minimal icon={<i className="fa-solid fa-caret-down"></i>} onClick={() => blockAction('move-down')} />
						</ButtonGroup>
						<Button
							small
							minimal
							intent="danger"
							icon={<i className="fa-solid fa-xmark"></i>}
							onClick={() => blockAction('delete')}
						/>
					</div>
				</div>
			</div>

			<div className="padded">
				<h4>
					{Lang.get('Brightness levels')}
					<div className="DayBrightnessEditorLegend">
						<div className="daylight">{Lang.get('Daylight')}</div>
						<div className="idle">{Lang.get('No Motion')}</div>
						<div className="active">{Lang.get('Motion')}</div>
					</div>
				</h4>
				<DayBrightnessEditor
					periods={blockData.brightnessLevels}
					conditions={blockData.conditions}
					sunrise={sunriseSunsetTimes.sunrise}
					sunset={sunriseSunsetTimes.sunset}
					setPeriodValue={setPeriodValue}
					splitPeriod={splitPeriod}
					deletePeriod={deletePeriod}
				/>
			</div>
			<div className="conditions">
				<h4>
					{Lang.get('Conditions')}
					<Popover2 content={menu} placement="bottom">
						<Button small minimal intent="success" icon={<i className="fa-solid fa-plus"></i>} />
					</Popover2>
				</h4>

				{hasConditions &&
					Object.keys(blockData.conditions).map((conditionType) => {
						const conditionData = blockData.conditions[conditionType]
						return (
							<Condition
								conditionType={conditionType}
								data={conditionData}
								parentConditions={blockData.conditions}
								key={`cb-${conditionType}`}
								onRemove={() => {
									removeCondition(conditionType)
								}}
								onValueChange={(newValue) => {
									blockAction('set-value', { conditions: { ...blockData.conditions, [conditionType]: newValue } })
								}}
							/>
						)
					})}

				{!hasConditions && <div className="noConditions">{Lang.get('No conditions')}</div>}
			</div>
			<div className="brightness-table-wrapper">
				<table className="bp5-html-table bp5-compact bp5-html-table-bordered">
					<thead>
						<tr>
							<th></th>
							<th>{Lang.get('Start')}</th>
							<th>{Lang.get('No Motion')} %</th>
							<th>{Lang.get('Motion')} %</th>
							<th className="row-action-cell"></th>
						</tr>
					</thead>
					<tbody>
						{blockData.brightnessLevels.map((period, i) => {
							const prevFake = blockData.brightnessLevels[i - 1]?.fake

							const isDayRow = blockData.conditions.nightlight && period.fake
							const isNightRow = blockData.conditions.daylight && period.fake

							return (
								<BrightnessTableRow
									key={`btr-${i}`}
									period={period}
									onStartChange={(newSeconds) => setPeriodValue(i, 'start', newSeconds)}
									onIdleBrightnessChange={(valueNumber) =>
										!(valueNumber < 0 || valueNumber > 100) &&
										!isNaN(valueNumber) &&
										setPeriodValue(i, 'idleBrightness', valueNumber / 100)
									}
									onActiveBrightnessChange={(valueNumber) =>
										!(valueNumber < 0 || valueNumber > 100) &&
										!isNaN(valueNumber) &&
										setPeriodValue(i, 'activeBrightness', valueNumber / 100)
									}
									i={i}
									disabledStart={i == 0 || period.fake || prevFake}
									disabledValues={period.fake}
									className={`${isDayRow && 'dayRow'} ${isNightRow && 'nightRow'}`}
									splitPeriod={splitPeriod}
									deletePeriod={deletePeriod}
									periods={blockData.brightnessLevels}
								/>
							)
						})}
					</tbody>
				</table>
			</div>
		</div>
	)
}

function BrightnessTableRow({
	period,
	periods,
	onStartChange,
	onIdleBrightnessChange,
	onActiveBrightnessChange,
	i,
	disabledStart,
	disabledValues,
	className,
	splitPeriod,
	deletePeriod
}) {
	const fake = period.fake

	const nextFake = periods[i + 1]?.fake
	const prevFake = periods[i - 1]?.fake

	return (
		<tr className={className}>
			<td className="num-cell">
				<div>{i + 1}.</div>
			</td>
			<td>
				<TimePicker
					value={secondsTodayToDate(period.start)}
					disabled={disabledStart}
					onChange={(newDate) => onStartChange(dateToSecondsToday(newDate))}
				/>
			</td>
			<td className="brightness-input idle">
				<NumericInput
					small
					minimal
					value={Math.round(period.idleBrightness * 100)}
					disabled={disabledValues}
					onValueChange={onIdleBrightnessChange}
					selectAllOnFocus={true}
				/>
			</td>
			<td className="brightness-input motion">
				<NumericInput
					small
					minimal
					value={Math.round(period.activeBrightness * 100)}
					disabled={disabledValues}
					onValueChange={onActiveBrightnessChange}
					selectAllOnFocus={true}
				/>
			</td>
			<td className="row-action-cell">
				<div className="row-action">
					<div className="tickerAction">
						<ButtonGroup>
							{!(nextFake && prevFake) &&
								!(i == 0 && nextFake) &&
								!(i == periods.length - 1 && prevFake) &&
								!(i == 0 && periods.length == 1) && (
									<Button
										small
										minimal
										intent="danger"
										icon={<i className="fa-solid fa-minus"></i>}
										onClick={() => deletePeriod(i)}
									/>
								)}
							{!fake && (
								<Button
									small
									minimal
									intent="success"
									icon={<i className="fa-solid fa-plus"></i>}
									onClick={() => splitPeriod(i)}
								/>
							)}
						</ButtonGroup>
					</div>
				</div>
			</td>
		</tr>
	)
}

// ===================================================================

function DayBrightnessEditor({ periods, conditions, sunrise, sunset, setPeriodValue, splitPeriod, deletePeriod }) {
	const periodsRef = useRef(null)
	const targetInfoRef = useRef(null)
	const modeRef = useRef(null)

	function timeToPercentage(time) {
		return (time / 86400) * 100
	}

	function timeToString(time) {
		let t = DateTime.now().set({ hour: 0, minute: 0, second: time })
		return t.toFormat('HH:mm')
	}

	function infoForEvent(e) {
		const periodsBounds = periodsRef.current.getBoundingClientRect()

		const xPercentage = ((e.clientX - periodsBounds.left) / periodsBounds.width) * 100

		let yPercentage = ((e.clientY - periodsBounds.top) / periodsBounds.height) * 100
		yPercentage = Math.max(0, Math.min(100, yPercentage))
		yPercentage = 100 - yPercentage

		let period = null
		let periodIndex = 0
		for (let p of periods) {
			const start = timeToPercentage(p.start)
			const end = timeToPercentage(p.start + p.duration)
			if (start <= xPercentage && xPercentage <= end) {
				period = p
				break
			}
			periodIndex++
		}

		return { period, periodIndex, xPercentage, yPercentage }
	}

	function onMouseDown(e) {
		const info = infoForEvent(e)
		targetInfoRef.current = info

		if (e.target.classList.contains('activeBrightnessHandle')) {
			modeRef.current = 'activeBrightness'
		} else if (e.target.classList.contains('idleBrightnessHandle')) {
			modeRef.current = 'idleBrightness'
		} else if (e.target.classList.contains('horizontalResizeHandle')) {
			modeRef.current = 'horizontalResize'
		}
	}

	function onMouseUp() {
		modeRef.current = null
	}

	function onMouseMove(e) {
		if (modeRef.current && targetInfoRef.current) {
			const info = infoForEvent(e)

			const curFake = targetInfoRef.current.period.fake
			if (curFake) return

			switch (modeRef.current) {
				case 'activeBrightness': {
					const newActiveBrightness = Math.round(info.yPercentage) / 100
					setPeriodValue(targetInfoRef.current.periodIndex, 'activeBrightness', newActiveBrightness)
					break
				}

				case 'idleBrightness': {
					const newIdleBrightness = Math.round(info.yPercentage) / 100
					setPeriodValue(targetInfoRef.current.periodIndex, 'idleBrightness', newIdleBrightness)
					break
				}

				case 'horizontalResize': {
					const newStart = Math.round((info.xPercentage / 100) * 86400)
					setPeriodValue(targetInfoRef.current.periodIndex + 1, 'start', newStart)
					break
				}
			}
		}
	}

	return (
		<div className="DayBrightnessEditor" onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseMove={onMouseMove}>
			<div className="periods" ref={periodsRef}>
				{periods.map((period, i) => {
					const fake = period.fake
					const nextFake = periods[i + 1]?.fake

					return (
						<div
							key={`pp-${i}`}
							className="period"
							style={{ width: timeToPercentage(period.duration) + '%', left: timeToPercentage(period.start) + '%' }}>
							<div className="activeValueSlider" style={{ height: period.activeBrightness * 100 + '%' }}>
								<div className="handle activeBrightnessHandle" data-disabled={fake}>
									{Math.round(period.activeBrightness * 100)}%
								</div>
							</div>
							<div className="idleValueSlider" style={{ height: period.idleBrightness * 100 + '%' }}>
								<div className="handle idleBrightnessHandle" data-disabled={fake}>
									{Math.round(period.idleBrightness * 100)}%
								</div>
							</div>

							<div className="horizontalResizeHandle" data-disabled={fake || nextFake} />
						</div>
					)
				})}
			</div>
			<div className="tickers">
				{periods.map((period, i) => {
					const fake = period.fake

					const nextFake = periods[i + 1]?.fake
					const prevFake = periods[i - 1]?.fake

					return (
						<React.Fragment key={`tp-${i}`}>
							<div
								className={'ticker' + (targetInfoRef.current?.periodIndex == i - 1 ? ' active' : '')}
								style={{ left: timeToPercentage(period.start) + '%' }}>
								{timeToString(period.start)}
							</div>
							{!fake && (
								<div
									className="tickerActionWrap"
									style={{
										left: timeToPercentage(period.start + period.duration / 2) + '%'
										// width: timeToPercentage(period.duration) + '%'
									}}>
									<div className="tickerAction">
										<ButtonGroup>
											{!(nextFake && prevFake) &&
												!(i == 0 && nextFake) &&
												!(i == periods.length - 1 && prevFake) &&
												!(i == 0 && periods.length == 1) && (
													<Button
														small
														minimal
														intent="danger"
														icon={<i className="fa-solid fa-minus"></i>}
														onClick={() => deletePeriod(i)}
													/>
												)}
											<Button
												small
												minimal
												intent="success"
												icon={<i className="fa-solid fa-plus"></i>}
												onClick={() => splitPeriod(i)}
											/>
										</ButtonGroup>
									</div>
								</div>
							)}
						</React.Fragment>
					)
				})}
				<div className="ticker" style={{ left: timeToPercentage(86400) + '%' }}>
					00:00
				</div>
			</div>
			<div
				className="sunsetSunriseOverlay"
				style={{
					left: timeToPercentage(sunrise) + '%',
					right: 100 - timeToPercentage(sunset) + '%'
				}}></div>
		</div>
	)
}

// ===================================================================

function Condition({ conditionType, parentConditions, data, onRemove, onValueChange }) {
	let condition = conditionTypes.find((c) => c.type == conditionType)
	if (!condition) {
		condition = { label: conditionType, icon: 'fa-question' }
	}
	let valueEl = null
	if (conditionType == 'weekdays') {
		const value = parentConditions.weekdays
		const abriviations = 'Mon,Tue,Wed,Thu,Fri,Sat,Sun'.split(',')
		valueEl = (
			<ButtonGroup>
				{abriviations.map((abriv, i) => (
					<WeekdayConditionButton key={`cwcb${i}`} value={value} numValue={i + 1} label={abriv} onValueChange={onValueChange} />
				))}
			</ButtonGroup>
		)
	} else if (conditionType == 'date') {
		const value = parentConditions.date
		valueEl = (
			<DateInput2
				className="bp5-small"
				value={value}
				onChange={onValueChange}
				parseDate={(date) => null}
				formatDate={(date) => DateTime.fromJSDate(date).toFormat('yyyy-LL-dd')}
				highlightCurrentDay={true}
			/>
		)
	} else if (conditionType == 'daylight' || conditionType == 'nightlight') {
		const value = parentConditions[conditionType]
		valueEl = (
			<>
				<div className="lineBreak">
					<NumericInput
						small
						className="smallNumeric"
						value={value.sunriseOffset}
						onValueChange={(val) => !isNaN(val) && onValueChange({ ...value, sunriseOffset: val })}
					/>
					<span className="small">{Lang.get('sunrise offset')}</span>
				</div>
				<div className="lineBreak">
					<NumericInput
						small
						className="smallNumeric"
						value={value.sunsetOffset}
						onValueChange={(val) => !isNaN(val) && onValueChange({ ...value, sunsetOffset: val })}
					/>
					<span className="small">{Lang.get('sunset offset')}</span>
				</div>
			</>
		)
	} else {
		valueEl = <pre>{JSON.stringify(data, null, 2)}</pre>
	}
	return (
		<div className="Condition">
			<div className="icon">
				<i className={`fa-fw fa-solid ${condition.icon}`}></i>
			</div>
			<div className="label">{condition.label}</div>
			<div className="value">{valueEl}</div>

			<div className="removeButton">
				<Button small minimal intent="danger" icon={<i className="fa-solid fa-xmark"></i>} onClick={onRemove} />
			</div>
		</div>
	)
}

// ===================================================================

function WeekdayConditionButton({ value, numValue, label, onValueChange }) {
	const isActive = value.includes(numValue)
	return (
		<Button
			small
			intent={isActive ? 'primary' : undefined}
			onClick={() => {
				onValueChange(isActive ? value.filter((v) => v != numValue) : [...value, numValue])
			}}>
			{label}
		</Button>
	)
}

// ===================================================================
