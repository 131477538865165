import { FormGroup, Slider } from '@blueprintjs/core'
import { useEffect, useState } from 'react'

function percentLabelRenderer(val) {
	return val + '%'
}

export default function ProfileV1Editor({ profile, onChange }) {
	// const [daliSpeed, setDaliSpeed] = useState(parseInt(profile.daliSpeed, 10))
	// const [motionTime, setMotionTime] = useState(parseInt(profile.motionTime, 10))
	// const [sunriseOffset, setSunriseOffset] = useState(parseInt(profile.sunriseOffset, 10))
	// const [sunsetOffset, setSunsetOffset] = useState(parseInt(profile.sunsetOffset, 10))
	// const [dayBrightness, setDayBrightness] = useState(parseInt(profile.dayBrightness, 10))
	// const [nightBrightness, setNightBrightness] = useState(parseInt(profile.nightBrightness, 10))
	// const [nightBrightnessMotion, setNightBrightnessMotion] = useState(parseInt(profile.nightBrightnessMotion, 10))
	// const [nightBrightnessN1, setNightBrightnessN1] = useState(parseInt(profile.nightBrightnessN1, 10))
	// const [nightBrightnessN2, setNightBrightnessN2] = useState(parseInt(profile.nightBrightnessN2, 10))
	// const [nightBrightnessN3, setNightBrightnessN3] = useState(parseInt(profile.nightBrightnessN3, 10))
	// const [nightBrightnessN4, setNightBrightnessN4] = useState(parseInt(profile.nightBrightnessN4, 10))
	// const [nightBrightnessN5, setNightBrightnessN5] = useState(parseInt(profile.nightBrightnessN5, 10))

	const [state, setState] = useState({
		daliSpeed: parseInt(profile.daliSpeed, 10),
		motionTime: parseInt(profile.motionTime, 10),
		sunriseOffset: parseInt(profile.sunriseOffset, 10),
		sunsetOffset: parseInt(profile.sunsetOffset, 10),
		dayBrightness: parseInt(profile.dayBrightness, 10),
		nightBrightness: parseInt(profile.nightBrightness, 10),
		nightBrightnessMotion: parseInt(profile.nightBrightnessMotion, 10),
		nightBrightnessN1: parseInt(profile.nightBrightnessN1, 10),
		nightBrightnessN2: parseInt(profile.nightBrightnessN2, 10),
		nightBrightnessN3: parseInt(profile.nightBrightnessN3, 10),
		nightBrightnessN4: parseInt(profile.nightBrightnessN4, 10),
		nightBrightnessN5: parseInt(profile.nightBrightnessN5, 10)
	})

	function setProfileValue(key, value) {
		setState((cur) => ({ ...cur, [key]: value }))
	}

	useEffect(() => {
		onChange(state)
	}, [JSON.stringify(state)])

	const p = state

	return (
		<div className="ProfileV1Editor">
			<FormGroup label="Spožuma maiņas ātrums (DALI)">
				<Slider max={7} value={p.daliSpeed} onChange={(val) => setProfileValue('daliSpeed', val)} />
			</FormGroup>

			<FormGroup label="Kustības laiks" labelInfo="(sekundes)">
				<Slider max={600} value={p.motionTime} onChange={(val) => setProfileValue('motionTime', val)} labelStepSize={60} />
			</FormGroup>

			<FormGroup label="Saullēkta nobīde (+/-)" labelInfo="(minūtes)">
				<Slider
					min={-120}
					max={120}
					value={p.sunriseOffset}
					onChange={(val) => setProfileValue('sunriseOffset', val)}
					labelStepSize={30}
				/>
			</FormGroup>

			<FormGroup label="Saulrieta nobīde (+/-)" labelInfo="(minūtes)">
				<Slider
					min={-120}
					max={120}
					value={p.sunsetOffset}
					onChange={(val) => setProfileValue('sunsetOffset', val)}
					labelStepSize={30}
				/>
			</FormGroup>

			<div className="heading">Gaismas spožumi</div>

			<FormGroup label="Dienā">
				<Slider
					max={100}
					value={p.dayBrightness}
					onChange={(val) => setProfileValue('dayBrightness', val)}
					labelRenderer={percentLabelRenderer}
					labelStepSize={25}
				/>
			</FormGroup>

			<FormGroup label="Naktī, bez satiksmes">
				<Slider
					max={100}
					value={p.nightBrightness}
					onChange={(val) => setProfileValue('nightBrightness', val)}
					labelRenderer={percentLabelRenderer}
					labelStepSize={25}
				/>
			</FormGroup>

			<FormGroup label="Naktī, satiksmē">
				<Slider
					max={100}
					value={p.nightBrightnessMotion}
					onChange={(val) => setProfileValue('nightBrightnessMotion', val)}
					labelRenderer={percentLabelRenderer}
					labelStepSize={25}
				/>
			</FormGroup>

			<FormGroup label="Naktī, satiksmē pie blakus staba">
				<Slider
					max={100}
					value={p.nightBrightnessN1}
					onChange={(val) => setProfileValue('nightBrightnessN1', val)}
					labelRenderer={percentLabelRenderer}
					labelStepSize={25}
				/>
			</FormGroup>

			<FormGroup label="Naktī, satiksmē pie otrā blakus staba">
				<Slider
					max={100}
					value={p.nightBrightnessN2}
					onChange={(val) => setProfileValue('nightBrightnessN2', val)}
					labelRenderer={percentLabelRenderer}
					labelStepSize={25}
				/>
			</FormGroup>

			<FormGroup label="Naktī, satiksmē pie trešā blakus staba">
				<Slider
					max={100}
					value={p.nightBrightnessN3}
					onChange={(val) => setProfileValue('nightBrightnessN3', val)}
					labelRenderer={percentLabelRenderer}
					labelStepSize={25}
				/>
			</FormGroup>

			<FormGroup label="Naktī, satiksmē pie ceturtā blakus staba">
				<Slider
					max={100}
					value={p.nightBrightnessN4}
					onChange={(val) => setProfileValue('nightBrightnessN4', val)}
					labelRenderer={percentLabelRenderer}
					labelStepSize={25}
				/>
			</FormGroup>

			<FormGroup label="Naktī, satiksmē pie piektā blakus staba">
				<Slider
					max={100}
					value={p.nightBrightnessN5}
					onChange={(val) => setProfileValue('nightBrightnessN5', val)}
					labelRenderer={percentLabelRenderer}
					labelStepSize={25}
				/>
			</FormGroup>
		</div>
	)
}
