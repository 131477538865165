import './index.scss'

import { Chart } from 'chart.js'
import autocolors from 'chartjs-plugin-autocolors'
import { color } from 'chart.js/helpers'
const lighten = (col, value) => color(col).lighten(value).rgbString()

import Page from '../../Components/Page'
import Lang from '../../Lang'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import API from '../../API'

import { Button, Spinner } from '@blueprintjs/core'
import { DateTime } from 'luxon'
import { download, findKeyByProperty, roundToFirstTwoNonZeroDecimals } from '../../Util'
import { keyDetails } from '../Devices/Components/DeviceDetails'
import DeviceFilter from './Components/DeviceFilter'
import PeriodPicker from './Components/PeriodPicker'
import ParameterSelector from './Components/ParameterSelector'
import ChartViewSettings from './Components/ChartViewSettings'
import ExportDialog from './Components/ExportDialog'
import Placeholder from '../../Components/Placeholder'

async function getProfiles() {
	const profiles = await API.call('LightingProfile.ListWithDevices')
	return profiles
}

async function getStatistics(step, from, to, deviceIds = []) {
	const args = {
		from,
		to,
		step
	}

	if (deviceIds.length > 0) {
		args.deviceIds = deviceIds
	}

	const ret = await API.call('Statistics.Full', args)
	return ret
}

async function getCSV(from, to, deviceIds = []) {
	const args = {
		from,
		to
	}

	if (deviceIds.length > 0) {
		args.deviceIds = deviceIds
	}

	const ret = await API.call('Statistics.CSV', args)
	return ret
}

const STATES = {
	OK: 0,
	LOADING: 1,
	EMPTY: 2
}

const __ZERO = 0.000001337

export default function ReportsPage() {
	const [state, setState] = useState(STATES.LOADING)
	const chartRef = useRef(null)
	const canvasRef = useRef(null)

	const [profiles, setProfiles] = useState(null)

	const [dateRange, setDateRange] = useState([DateTime.now(), DateTime.now()])
	const [viewSettings, setViewSettings] = useState({
		step: 'hour'
	})

	const { step } = viewSettings

	const [activeDeviceIDs, setActiveDeviceIDs] = useState([])
	const [fieldStates, setFieldStates] = useState({})

	const [labels, setLabels] = useState(null)
	const [values, setValues] = useState(null)
	const curDatasets = useRef([])

	const fetchKey = useRef('')
	async function populateData() {
		setState(STATES.LOADING)

		const [start, end] = dateRange

		const keyCur = window.crypto.randomUUID()
		fetchKey.current = keyCur

		const data = await getStatistics(step, start.toFormat('yyyy-LL-dd'), end.toFormat('yyyy-LL-dd'), activeDeviceIDs)

		if (fetchKey.current != keyCur) {
			return
		}

		const labels = Object.keys(data).map((k) => DateTime.fromISO(k))
		labels.sort()

		const values = []
		for (const label of labels) {
			values.push(data[label.toISO()])
		}

		setLabels(labels)
		setValues(values)
		setState(Object.keys(data).length === 0 ? STATES.EMPTY : STATES.OK)
	}

	async function queryData() {
		if (!profiles) {
			const p = await getProfiles()
			setProfiles(p)
		}

		await populateData()
	}

	useEffect(() => {
		if (!chartRef.current) {
			chartRef.current = new Chart(canvasRef.current, {
				type: 'line',
				plugins: [autocolors],
				options: {
					spanGaps: false,
					responsive: true,
					pointHitRadius: 10,
					maintainAspectRatio: false,
					interaction: {
						intersect: false,
						mode: 'index'
					},
					animation: {
						duration: 0
					},
					scales: {
						y: {
							type: 'linear',
							min: 0,
							max: 1,
							grid: {
								display: false
							},
							ticks: {
								display: false
							}
						}
					},
					plugins: {
						autocolors: {
							customize(context) {
								const colors = context.colors
								return {
									background: lighten(colors.background, 0.1),
									border: lighten(colors.border, 0.1)
								}
							}
						},
						tooltip: {
							usePointStyle: true,
							callbacks: {
								label: (context) => {
									const datasetIndex = context.datasetIndex
									const dataIndex = context.dataIndex
									const dataset = curDatasets.current[datasetIndex]

									const val = parseFloat(dataset.data[dataIndex])
									const displayVal = val === __ZERO ? 0 : roundToFirstTwoNonZeroDecimals(val)

									return `${dataset.label}: ${displayVal}`
								},
								title: (context) => {
									const c = context[0]
									const t = DateTime.fromMillis(c.parsed.x)

									let title = c.label

									setViewSettings((vs) => {
										if (vs.step === 'hour') title = t.toFormat('LLL dd, HH:mm')
										if (vs.step === 'day') title = t.toFormat('LLL dd')
										if (vs.step === 'month') title = t.toFormat('LLL')

										return vs
									})

									return title
								}
							}
						},
						legend: {
							onClick: (e, legendItem, legend) => {
								// default behaviour
								const index = legendItem.datasetIndex
								const ci = legend.chart
								if (ci.isDatasetVisible(index)) {
									ci.hide(index)
									legendItem.hidden = true
								} else {
									ci.show(index)
									legendItem.hidden = false
								}
								// end of default behaviour

								const { _dataset } = ci.getDatasetMeta(index)
								if (_dataset?.fieldName) {
									setFieldStates((old) => {
										const dic = findKeyByProperty(old, _dataset.fieldName)
										old[dic][_dataset.fieldName] = !old[dic][_dataset.fieldName]
										return { ...old }
									})
								}
							}
						}
					}
				}
			})
		}

		queryData()
	}, [])

	const flatFieldStates = useMemo(() => {
		const ret = {}

		for (const dic in fieldStates) {
			for (const field in fieldStates[dic]) {
				ret[field] = fieldStates[dic][field]
			}
		}

		return ret
	}, [fieldStates])

	useEffect(() => {
		if (!labels || !values) return

		let datasets = Object.keys(flatFieldStates).map((field) => {
			const vals = values.map((r) => {
				if (!r) return null

				if (r[field] != undefined) {
					const float = parseFloat(r[field])
					return float == 0 ? __ZERO : float
				}

				return null
			})

			const getLabel = () => {
				const fieldAttrs = keyDetails[field]
				if (!fieldAttrs) return field

				const unit = fieldAttrs.unit
				return `${fieldAttrs.label} ${unit ? `(${unit})` : ''}`.trim()
			}

			const ret = {
				label: getLabel(),
				borderWidth: 1,
				data: vals,
				fieldName: field,
				hidden: !flatFieldStates[field],
				fill: false,
				barPercentage: 1.24
			}

			if (field === 'energyConsumption') {
				ret.type = 'bar'
				ret.backgroundColor = '#5eff6150'
				ret.borderColor = '#5eff6150'
				ret.borderWidth = 5
			}

			return ret
		})

		curDatasets.current = datasets

		datasets = datasets.map((dataset) => {
			const maxVal = Math.max(...dataset.data)
			const minVal = Math.min(...dataset.data)
			return {
				...dataset,
				data: dataset.data.map((val, i) => {
					if (!dataset.data?.[i]) return null

					return val / maxVal
				})
			}
		})

		chartRef.current.data = {
			labels,
			datasets
		}

		chartRef.current.options.scales.x = {
			type: 'time',
			time: {
				unit: step,
				displayFormats: {
					hour: 'HH:mm',
					day: 'LLL dd',
					month: 'LLL'
				}
			},
			ticks: {
				major: {
					enabled: true
				},
				callback: (val, idx) => {
					const date = DateTime.fromMillis(val)
					if (step == 'hour') {
						const diffHrs = dateRange[1].diff(dateRange[0], 'hours').toObject().hours
						if (diffHrs >= 24 && idx % 24 === 0) {
							return date.toFormat('LLL dd')
						}

						return date.toFormat('HH:mm')
					}

					if (step == 'day') {
						return date.toFormat('LLL dd')
					}

					return date.toFormat('LLL')
				},
				font: (ctx, x) => (ctx.tick?.major ? { weight: 'bold' } : {})
			},
			grid: {
				lineWidth: (ctx) => (ctx.tick?.major && ctx.index ? 2 : 1),
				color: (ctx) => (ctx.tick?.major && ctx.index ? `rgba(0, 0, 0, 0.33)` : `rgba(30, 30, 30, 0.1)`)
			}
		}

		chartRef.current.options.scales.y.max = 1.01

		chartRef.current.update()
	}, [labels, values, fieldStates])

	const userDeviceIds = useMemo(() => {
		// console.log(DateTime.now().toFormat('yyyy-mm-dd HH:mm:ss'))
		return profiles ? profiles.map((p) => p.deviceIds).flat(1) : []
	}, [profiles])

	const formattedDate = useMemo(() => {
		const [from, to] = [dateRange[0].toFormat('dd LLL, yyyy'), dateRange[1].toFormat('dd LLL, yyyy')]

		if (from === to) return from

		return `${from} - ${to}`
	}, [dateRange])

	const [wantsCSV, setWantsCSV] = useState(false)
	const downloadCSV = useCallback(
		async (type = 'raw') => {
			const [from, to] = [dateRange[0].toFormat('yyyy-LL-dd'), dateRange[1].toFormat('yyyy-LL-dd')]

			if (type == 'raw') {
				const csv = await getCSV(from, to, activeDeviceIDs)
				download(csv, `IdeaLights Raw Data (${from == to ? from : `${from} - ${to}`})`)
				return
			}

			if (type == 'chart') {
				if (!values || !labels) return

				const rows = [
					[
						Lang.get('Time'),
						...Object.keys(flatFieldStates).map((field, idx) => {
							const attrs = keyDetails[field]

							const unit = attrs.unit
							return `${attrs.label || field}` + (unit ? ` ${unit}` : '')
						})
					]
				]

				for (const [idx, val] of Object.entries(values)) {
					const label = labels[idx]
					if (!label) continue

					const time = label.toFormat(
						(() => {
							switch (step) {
								case 'hour':
									return 'yyyy-LL-dd HH:mm:ss'
								case 'day':
									return 'LLL dd yyyy'
								case 'month':
									return 'LLL yyyy'
							}
						})()
					)

					const slice = [time]

					for (const field of Object.keys(flatFieldStates)) {
						slice.push(val[field] ? parseFloat(val[field]).toFixed(3) : 0)
					}

					rows.push(slice)
				}

				const csv = rows.map((r) => r.join(',')).join('\n')
				download(csv, `IdeaLights Chart Processed Data (${from == to ? from : `${from} - ${to}`})`)
				return
			}
		},
		[dateRange, activeDeviceIDs, values, labels]
	)

	return (
		<>
			<Page title={Lang.get('Reports')} icon="fa-duotone fa-chart-simple" className="report-page">
				<div className="toolbar">
					<div>
						<div className="top-left">
							<DeviceFilter profiles={profiles} onChange={setActiveDeviceIDs} />
							<ParameterSelector onChange={setFieldStates} />
							<ChartViewSettings onChange={setViewSettings} />

							<Button
								text={'CSV'}
								icon="download"
								onClick={() => setWantsCSV(true)}
								disabled={profiles == null || labels == null || values == null}
							/>
							<Button
								text={Lang.get('Query')}
								icon="search"
								onClick={() => queryData()}
								disabled={state == STATES.LOADING}
								intent="primary"
							/>
						</div>
						<div className="formatted-date">{formattedDate}</div>
						<PeriodPicker onChange={setDateRange} />
					</div>
				</div>
				<div className="view">
					{labels == null && values == null ? (
						<Placeholder loading label={Lang.get('Loading reports') + ' ...'} />
					) : (
						<>
							{state == STATES.LOADING && <Spinner className="spinner" />}
							{state == STATES.EMPTY && <span className="no-data">{Lang.get('No data')}</span>}
						</>
					)}
					<canvas ref={canvasRef} style={{ ...(labels == null && labels == null && { display: 'none' }) }}></canvas>
				</div>

				{labels != null && values != null && (
					<div className="chart-table">
						<table className="bp5-html-table bp5-compact bp5-html-table-bordered bp5-html-table-striped">
							<thead>
								<tr>
									<th>{Lang.get('Time')}</th>
									{Object.keys(flatFieldStates).map((field, idx) => {
										const attrs = keyDetails[field]

										const title = () => {
											const unit = attrs.unit

											return `${attrs.label || field}` + (unit ? ` ${unit}` : '')
										}

										return <th key={`tr-th-${idx}`}>{title()}</th>
									})}
								</tr>
							</thead>
							<tbody>
								{values.map((v, idx) => {
									const label = labels[idx]
									if (!label) return null

									const time = label.toFormat(
										(() => {
											switch (step) {
												case 'hour':
													return 'yyyy-LL-dd HH:mm:ss'
												case 'day':
													return 'LLL dd yyyy'
												case 'month':
													return 'LLL yyyy'
											}
										})()
									)

									return (
										<tr key={`tr-${idx}`}>
											<td>{time}</td>
											{Object.keys(flatFieldStates).map((field, idx) => {
												return <td key={`tr-td-${field}${idx}`}>{v?.[field] ? parseFloat(v[field]).toFixed(3) : 0}</td>
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				)}
			</Page>

			<ExportDialog
				asyncDownload={downloadCSV}
				title={Lang.get('CSV export') + `: ${formattedDate}`}
				open={wantsCSV}
				setOpen={setWantsCSV}>
				<p>
					<strong>
						{Lang.get('Selected device count: %d', activeDeviceIDs.length == 0 ? userDeviceIds.length : activeDeviceIDs.length)}
					</strong>
				</p>
			</ExportDialog>
		</>
	)
}
