import './index.scss'

import React, { useCallback, useEffect, useState } from 'react'
import Time from '../../../../Components/Time'
import { dali2percents, getChartTitleFromDataKey } from '../../../../Util'
import { DateTime } from 'luxon'
import API from '../../../../API'
import Lang from '../../../../Lang'
import Time from '../../../../Components/Time'
import { AdminOnly, isAdmin } from '../../../../Util'
import API, { RFCommand, RFPacket } from '../../../../API'
import { Button, ButtonGroup, Callout, TextArea, Tab, Tabs, InputGroup, Spinner, SpinnerSize } from '@blueprintjs/core'
import DeviceCommandViewer from './Components/DeviceCommandViewer'
import ChartGroup from './Components/ChartGroup'
import StatsChart from './Components/StatsChart'

// =============================================================================

// {haystack}.{needle}

// ----- haystack: -----

// stats => Device.GetStatistics
// 	   energyConsumption
// 	   trafficIntensity

// d4i => Device.GetD4IStatistics
// lampShortAddress
// nominalPower
// minDimLevelFromDriver
// currentDimLevelFromSchedule
// totalActiveEnergy
// driverOnTime
// mainsVoltage
// mainsCurrent
// powerFactor
// frequency
// driverFailureFlags
// driverTemperature
// ledCurrentPercent
// ledOnTime
// ledFailureFlags
// ledTemperature
// ledVoltage
// ledCurrent

// modbus => Device.GetModbusStatistics
// 	   consumption

// ----- needle ----- : vajadziigais key no haystack array ar datiem

const enabledGraphs = [
	'stats.energyConsumption',
	'modbus.consumption',

	'd4i.lampShortAddress',
	'd4i.currentDimLevelFromSchedule',
	'd4i.totalActiveEnergy',
	'd4i.driverOnTime',
	'd4i.mainsVoltage',
	'd4i.mainsCurrent',
	'd4i.powerFactor',
	'd4i.frequency',
	'd4i.driverFailureFlags',
	'd4i.driverTemperature',
	'd4i.ledOnTime',
	'd4i.ledFailureFlags',
	'd4i.ledTemperature',
	'd4i.ledVoltage',
	'd4i.ledCurrent'
]

function DeviceStats({ device }) {
	const [loading, setLoadingObject] = useState({
		stats: false,
		other: false
	})

	function setLoading(name, state) {
		setLoadingObject((o) => {
			return { ...o, [name]: state }
		})
	}

	const [data, setData] = useState({
		stats: null,
		d4i: null,
		modbus: null
	})

	const populateD4IModbus = useCallback(async (dateStr) => {
		const res = {
			d4i: null,
			modbus: null
		}

		try {
			const hasD4I = device.lastCommands?.find((c) => c.commandName == 'D4I')
			if (hasD4I) {
				setLoading('other', true)
				const fD4I = await API.call('Device.GetD4IStatistics', {
					deviceId: device.id,
					date: dateStr
				})

				if (fD4I) {
					res.d4i = fD4I.map((row) => {
						return {
							...row,
							time: DateTime.fromMillis(row.time)
						}
					})
				}
			}

			const hasModbus = device.lastCommands?.find((c) => c.commandName == 'MODBUS_METER')
			if (hasModbus) {
				setLoading('other', true)
				const fModbus = await API.call('Device.GetModbusStatistics', {
					deviceId: device.id,
					date: dateStr
				})

				if (fModbus) {
					res.modbus = fModbus.map((row) => {
						return { ...row, time: DateTime.fromMillis(row.time) }
					})
				}
			}

			setLoading('other', false)
		} catch (err) {
			console.log('D4IModbusFetchErr', err)
			setLoading('other', false)
		}

		setData((cur) => {
			return { ...cur, ...res }
		})
	})

	useEffect(() => {
		async function populateData() {
			try {
				setLoading('stats', true)
				const hourlyStats = await API.call('Device.GetStatistics', { deviceId: device.id })
				if (!hourlyStats) throw '!hourlyStats'

				hourlyStats.reverse()
				setData((cur) => {
					cur.stats = hourlyStats.map((row) => {
						return {
							...row,
							time: DateTime.fromISO(row.time),
							energyConsumption: (row.energyConsumption / 1000 / 3.6).toFixed(3)
						}
					})
					return { ...cur }
				})

				setLoading('stats', false)
			} catch (err) {
				console.log('DeviceStats error', err)
				setLoading('stats', false)
			}
		}

		populateData()
	}, [])

	async function onChangeCurDate(newDate) {
		await populateD4IModbus(newDate)
	}

	return (
		<ChartGroup onChangeCurDate={onChangeCurDate}>
			{enabledGraphs.map((name) => {
				const [haystackName, needleKey] = name.split('.')

				const arr = data[haystackName]
				if (!arr) return null

				const chartLoading =
					(loading.other && (haystackName == 'd4i' || haystackName == 'modbus')) || (loading.stats && haystackName == 'stats')

				const getTitle = () => {
					const keyData = keyDetails[needleKey]
					if (!keyData) return haystackName + '.' + needleKey

					const unit = keyData.unit

					return `${keyData.label} ${unit ? `(${unit})` : ''}`.trim()
				}

				return (
					<StatsChartBlock
						key={`stats-chart-${haystackName}-${needleKey}`}
						title={getTitle()}
						data={arr}
						displayKey={needleKey}
						loading={chartLoading}
					/>
				)
			})}
		</ChartGroup>
	)
}

function StatsChartBlock(props) {
	return (
		<DeviceDetailsBlock title={props.title} className="chartBlock">
			<StatsChart {...props} />
		</DeviceDetailsBlock>
	)
}

// =============================================================================

export const keyDetails = {
	energyConsumption: { label: Lang.get('Consumption'), unit: 'kWh' },
	trafficIntensity: { label: Lang.get('Traffic') },

	consumption_mb: { label: Lang.get('MB Consumption') },
	voltage_mb: { label: Lang.get('MB Voltage') },
	current_mb: { label: Lang.get('MB Current') },
	activePower_mb: { label: Lang.get('MB Active power') },
	reactivePower_mb: { label: Lang.get('MB Reactive power') },
	apparentPower_mb: { label: Lang.get('MB Apparent power') },
	totalActiveEnergy_mb: { label: Lang.get('MB Total active energy') },

	activePower: { label: Lang.get('Active Power'), unit: 'W' },
	lampShortAddress: { label: Lang.get('Lamp Short Address'), unit: '' },
	nominalPower: { label: Lang.get('Nominal Power'), unit: 'W', format: (v) => (v == 65535 ? '-' : v) },
	minDimLevelFromDriver: { label: Lang.get('Min Dim Level From Driver'), unit: '', type: 'dalivalue' },
	currentDimLevelFromSchedule: { label: Lang.get('Current Dim Level'), unit: '', type: 'dalivalue' },
	totalActiveEnergy: { label: Lang.get('Total Active Energy'), unit: 'kWh', format: (v) => v.toFixed(3) },
	driverOnTime: { label: Lang.get('Driver On Time'), unit: 's', type: 'duration' },
	mainsVoltage: { label: Lang.get('Mains Voltage'), unit: 'V', format: (v) => (v / 10).toFixed(1) },
	mainsCurrent: { label: Lang.get('Mains Current'), unit: 'mA' },
	powerFactor: { label: Lang.get('Power Factor'), unit: '', format: (v) => (v / 100).toFixed(2) },
	frequency: { label: Lang.get('Frequency'), unit: 'Hz' },
	driverFailureFlags: { label: Lang.get('Driver Failure Flags'), unit: '' },
	driverTemperature: { label: Lang.get('Driver Temperature'), unit: '°C' },
	ledOnTime: { label: Lang.get('LED On Time'), unit: 's', type: 'duration' },
	ledFailureFlags: { label: Lang.get('LED Failure Flags'), unit: '' },
	ledTemperature: { label: Lang.get('LED Temperature'), unit: '°C', format: (v) => (v < -60 ? '-' : v) },
	ledVoltage: { label: Lang.get('LED Voltage'), unit: 'V', format: (v) => (v / 10).toFixed(1) },
	ledCurrent: { label: Lang.get('LED Current'), unit: 'mA' }
}

function DeviceDetailsD4IBlock(props) {
	const d4i = props.device.lastCommands.find((c) => c.commandName == 'D4I')
	if (d4i?.payload?.subCommandName != 'MEASUREMENT_REPORT') {
		return null
	}

	return (
		<DeviceDetailsBlock title="D4I Report">
			<div className="reportLine">
				<span>Received</span>
				<span className="value">
					<Time timestamp={d4i.time / 1000} />
				</span>
			</div>
			{Object.keys(keyDetails).map((key) => {
				const value = d4i.payload[key]
				if (value === undefined) {
					return null
				}
				const keyData = keyDetails[key]
				let formattedValue = value
				if (keyData.format) {
					formattedValue = keyData.format(value)
				}
				if (keyData.type == 'duration') {
					formattedValue = (value / 3600).toFixed(0) + ' h'
				} else if (keyData.type == 'dalivalue') {
					formattedValue = /*'(' + value + ') ' + */ dali2percents(value) + '%'
				}
				return (
					<div className="reportLine" key={`report-line-${key}`}>
						<span>{keyData.label}</span>
						<span className="value">
							{formattedValue} {keyData.unit}
						</span>
					</div>
				)
			})}
		</DeviceDetailsBlock>
	)
}

// =============================================================================

export default function DeviceDetails({ device, view }) {
	const [selectedTabId, setSelectedTabId] = useState('general')

	const d = device
	if (!d) return null

	return (
		<div className="DeviceDetails" key={d.id}>
			<div className="closeButton">
				<Button icon="cross" minimal onClick={() => view.selectDevice(null)} />
			</div>
			<div className="modelBlock">
				<span>{Lang.get('C4 Controller')}</span>
			</div>
			<div className="idBlock">
				<span>{d.id}</span>
			</div>
			<Tabs className="sectionTabs" onChange={(tab) => setSelectedTabId(tab)} selectedTabId={selectedTabId}>
				<Tab
					id="general"
					title={Lang.get('General')}
					panel={
						<React.Fragment>
							<DeviceDetailsInfoBlock device={d} view={view} />
							<DeviceDetailsActionBlock device={d} view={view} />
							{/* <DeviceNeighboursBlock device={d} view={this.props.view} /> */}
							<DeviceDetailsD4IBlock device={d} />
						</React.Fragment>
					}
				/>
				<Tab id="history" title={Lang.get('History')} panel={<DeviceStats device={d} />} />
				{isAdmin() && (
					<Tab
						id="admin"
						title="Admin"
						panel={
							<React.Fragment>
								<DeviceDetailsBlock title="Details">
									Received <strong>{d.reportHealth}</strong> reports in last 24h
								</DeviceDetailsBlock>
								<DeviceDetailsGSMBlock device={d} />
								<DeviceDetailsGatewayBlock device={d} />
								<DeviceDetailsBlock title="Last Received Commands">
									<DeviceCommandViewer commands={d.lastCommands} device={d} />
								</DeviceDetailsBlock>
								<DeviceDetailsConfigurationBlock device={d} />
								<DeviceDetailsStreetConfigurationBlock device={d} />
							</React.Fragment>
						}
					/>
				)}
			</Tabs>
		</div>
	)
}

// =============================================================================

class DeviceDetailsBlock extends React.Component {
	render() {
		const className = ['DeviceDetailsBlock']
		if (this.props.className) {
			className.push(this.props.className)
		}
		return (
			<div className={className.join(' ')}>
				<div className="heading">{this.props.title}</div>
				<div className="content">{this.props.children}</div>
			</div>
		)
	}
}

// =============================================================================

class DeviceNeighboursBlock extends React.Component {
	constructor(props) {
		super(props)
		this.newDeviceIdRef = React.createRef()
		this.newMultiplierRef = React.createRef()
		this.state = {
			items: JSON.parse(JSON.stringify(props.device.neighbours)),
			hasChanges: false,
			busy: false
		}
	}

	add() {
		const deviceId = this.newDeviceIdRef.current.value
		const multiplier = this.newMultiplierRef.current.value

		if (!deviceId || !multiplier) {
			return
		}

		const items = this.state.items

		items.push({ id: deviceId, multiplier: multiplier })
		this.setState({ items: items }, () => {
			this.afterChange()
		})

		this.newDeviceIdRef.current.value = ''
		this.newMultiplierRef.current.value = ''
	}

	remove(n) {
		const items = this.state.items
		const index = items.indexOf(n)
		if (index > -1) {
			items.splice(index, 1)
		}
		this.setState({ items: items }, () => {
			this.afterChange()
		})
	}

	afterChange() {
		const hasChanges = JSON.stringify(this.props.device.neighbours) != JSON.stringify(this.state.items)
		this.setState({ hasChanges: hasChanges })
	}

	async save() {
		if (this.state.busy) return
		this.setState({ busy: true })
		const preValues = {}
		for (let n of this.state.items) {
			const id = parseInt(n.id, 10)
			preValues[id] = parseFloat(n.multiplier)
		}
		let outValues = []
		for (let neighbourId in preValues) {
			outValues.push({ id: neighbourId, multiplier: preValues[neighbourId] })
		}
		console.log('Device.SetNeighbours', { deviceId: this.props.device.id, neighbours: outValues })
		let res = await API.call('Device.SetNeighbours', { deviceId: this.props.device.id, neighbours: outValues })
		console.log(res)
		if (res.success) {
			this.setState({ busy: false }, () => {
				this.props.device.neighbours = this.state.items
				this.afterChange()
			})
		} else {
			this.setState({ busy: false })
			alert('Error saving neighbours')
		}
	}

	changeItem(n, key, value) {
		n[key] = value
		this.setState({ items: this.state.items }, () => {
			this.afterChange()
		})
	}

	render() {
		return (
			<DeviceDetailsBlock title={Lang.get('Neighbour Lights')}>
				<table>
					<tr>
						<th>{Lang.get('Device ID')}</th>
						<th>{Lang.get('Multiplier (0-1)')}</th>
					</tr>
					{this.state.items.map((n) => (
						<tr>
							<td>
								<InputGroup value={n.id} onChange={(event) => this.changeItem(n, 'id', event.target.value)} />
							</td>
							<td>
								<InputGroup value={n.multiplier} onChange={(event) => this.changeItem(n, 'multiplier', event.target.value)} />
							</td>
							<td>
								<Button icon="cross" minimal intent="danger" onClick={() => this.remove(n)} />
							</td>
						</tr>
					))}
					<tr>
						<td>
							<InputGroup inputRef={this.newDeviceIdRef} placeholder="#" />
						</td>
						<td>
							<InputGroup inputRef={this.newMultiplierRef} placeholder="0.0 - 1.0" />
						</td>
						<td>
							<Button icon="plus" minimal intent="primary" onClick={() => this.add()} />
						</td>
					</tr>
				</table>
				{this.state.hasChanges && (
					<p>
						<Button
							text={this.state.busy ? Lang.get('Saving...') : Lang.get('Save Neighbours')}
							disabled={this.state.busy}
							intent="success"
							onClick={() => this.save()}
							fill
						/>
					</p>
				)}
			</DeviceDetailsBlock>
		)
	}
}

// =============================================================================

class DeviceDetailsInfoBlock extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			busy: false,
			response: null,
			device: props.device,
			deviceUnsaved: false
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.device.id !== this.props.device.id) {
			this.setState({ device: this.props.device })
		}
	}

	updateDeviceInfo(event) {
		const device = this.state.device
		device.info = event.target.value
		this.setState({
			device: device,
			deviceUnsaved: true
		})
	}
	updateDeviceTitle(event) {
		const device = this.state.device
		device.title = event.target.value
		this.setState({
			device: device,
			deviceUnsaved: true
		})
	}

	updateDeviceValue(key, event) {
		const device = this.state.device
		device[key] = event.target.value
		this.setState({
			device: device,
			deviceUnsaved: true
		})
	}

	async save() {
		this.setState({ busy: true })

		const deviceUpdateData = {
			id: this.state.device.id,
			info: this.state.device.info,
			title: this.state.device.title
		}

		if (this.state.device.definedNominalPower) {
			deviceUpdateData.definedNominalPower = this.state.device.definedNominalPower
		}

		await API.call('Admin.Device.Update', deviceUpdateData)
		this.props.view.selectDevice(this.state.device)
		this.setState({ busy: false, deviceUnsaved: false })
	}
	render() {
		const d = this.state.device
		console.log(d)
		return (
			<DeviceDetailsBlock>
				<div className="box">
					<span>{Lang.get('Last Heard')}: </span>
					<span className="value">
						<Time timestamp={d.lastDataTime} />
					</span>
				</div>
				<div className="box">
					<span>{Lang.get('Title')}: </span>
					<span className="value">{d.title}</span>
				</div>
				<AdminOnly>
					<div className="box">
						<InputGroup
							disabled={this.state.busy}
							value={this.state.device.title ?? ''}
							onChange={(e) => this.updateDeviceValue('title', e)}
						/>
					</div>
					<div className="box">
						<span>{Lang.get('Info')}: </span>
						<InputGroup
							disabled={this.state.busy}
							value={this.state.device.info ?? ''}
							onChange={(e) => this.updateDeviceValue('info', e)}
						/>
					</div>
					<div className="box">
						<span>{Lang.get('Nominal Power')} (W): </span>
						<InputGroup
							disabled={this.state.busy}
							value={this.state.device.definedNominalPower ?? ''}
							onChange={(e) => this.updateDeviceValue('definedNominalPower', e)}
							type="number"
						/>
					</div>

					<div className="box">
						<span>{Lang.get('Serial Nr')}: </span>
						<small className="value">{d.serialNr}</small>
					</div>
					{d.identity && (
						<div className="box">
							<span>{Lang.get('Firmware')}: </span>
							<span className="value">{d.identity.firmwareVersion}</span>
						</div>
					)}
					{this.state.deviceUnsaved && (
						<div className="box">
							<Button
								disabled={this.state.busy}
								icon={this.state.busy ? <Spinner size={SpinnerSize.SMALL} /> : false}
								text={Lang.get('Save')}
								onClick={() => this.save()}
								fill
							/>
						</div>
					)}
				</AdminOnly>
			</DeviceDetailsBlock>
		)
	}
}

// =============================================================================

class DeviceDetailsActionBlock extends React.Component {
	showOnMap() {
		this.props.view.zoomDevices([this.props.device])
	}

	showWaze() {
		const d = this.props.device
		const url = 'https://www.waze.com/ul?ll=' + d.latitude + ',' + d.longitude + '&navigate=yes'
		window.open(url, '_blank')
	}

	action(action) {
		const p = new RFPacket()

		if (action == 'on') {
			p.command = RFCommand.RELAY_TEST
			p.data = Buffer.from('01', 'hex')
		} else if (action == 'off') {
			p.command = RFCommand.RELAY_TEST
			p.data = Buffer.from('00', 'hex')
		} else if (action == 'blink') {
			p.command = RFCommand.IDENTIFY
		} else {
			return
		}

		API.sendPacket(p, this.props.device)
	}

	render() {
		const d = this.props.device
		return (
			<DeviceDetailsBlock title={Lang.get('Actions')} className="actions">
				<ButtonGroup fill>
					<Button icon="path-search" text={Lang.get('Show on Map')} onClick={() => this.showOnMap()} />
					<Button icon="geolocation" text="Waze" onClick={() => this.showWaze()} />
				</ButtonGroup>
				<AdminOnly>
					<DeviceDetailsPingButton device={d} />
					<Button icon="flash" text="Blink DALI" onClick={() => this.action('blink')} />
					<ButtonGroup fill>
						<Button icon="power" text="Relay ON" onClick={() => this.action('on')} />
						<Button text="Relay OFF" onClick={() => this.action('off')} />
					</ButtonGroup>
				</AdminOnly>
			</DeviceDetailsBlock>
		)
	}
}

export class DeviceDetailsPingButton extends React.Component {
	constructor(props) {
		super(props)
		this.state = { busy: false, response: null }
	}

	async ping() {
		if (this.state.busy) return
		this.setState({ busy: true, response: null })

		let benchmark = new Date()

		const p = new RFPacket()
		p.command = RFCommand.SCAN
		const r = await API.sendPacketAndWaitResponse(
			p,
			this.props.device,
			{
				deviceId: this.props.device.id,
				command: RFCommand.SCAN_REPORT
			},
			this.props.preferGateway ?? -1
		)
		if (r) {
			benchmark = new Date().getTime() - benchmark.getTime()
			this.setState({ busy: false, response: benchmark })
		} else {
			this.setState({ busy: false, response: -1 })
		}
	}

	render() {
		if (this.state.busy) {
			return <Button loading />
		} else {
			if (this.state.response) {
				if (this.state.response == -1) {
					return <Button icon="feed" intent="danger" text="Ping Failed" onClick={() => this.ping()} />
				} else {
					return (
						<Button icon="feed" intent="success" text={'Pinged in ' + this.state.response + ' ms'} onClick={() => this.ping()} />
					)
				}
			} else {
				return <Button icon="feed" text={Lang.get('Ping')} onClick={() => this.ping()} />
			}
		}
	}
}

// =============================================================================

class DeviceDetailsGSMBlock extends React.Component {
	componentDidMount() {
		API.call('agents.list')
		API.addMessageListener(this, 'agent-list', (res) => {
			let foundGateway = null
			for (let con of res.args) {
				if (con.scanReport && con.scanReport.deviceId == this.props.device.id) {
					foundGateway = con
				}
			}
			this.setState({ gateway: foundGateway })
		})
	}

	componentWillUnmount() {
		API.removeListener(this)
	}

	render() {
		const d = this.props.device
		if (!(this.state && this.state.gateway)) {
			return null
		}
		const gw = this.state.gateway
		return (
			<DeviceDetailsBlock title="GSM Connection">
				<table>
					<tbody>
						<tr>
							<td>Connected</td>
							<td className="value">
								<Time iso={gw.timeConnected} />
							</td>
						</tr>
						<tr>
							<td>Address</td>
							<td className="value">{gw.address}</td>
						</tr>

						<tr>
							<td>Firmware</td>
							<td className="value">{gw.scanReport.firmwareVersion}</td>
						</tr>
					</tbody>
				</table>
			</DeviceDetailsBlock>
		)
	}
}

// =============================================================================

class DeviceDetailsGatewayBlock extends React.Component {
	sortGateway(a, b) {
		if (a.lastDataTime > b.lastDataTime) {
			return -1
		} else if (a.lastDataTime < b.lastDataTime) {
			return 1
		}
		if (a.rssi > b.rssi) {
			return -1
		} else if (a.rssi < b.rssi) {
			return 1
		}
		return 0
	}
	render() {
		const d = this.props.device
		return (
			<DeviceDetailsBlock title="Gateways">
				<table>
					<tbody>
						{d.gateways?.sort(this.sortGateway).map((g, idx) => (
							<tr key={`ddgateway-${idx}${g.deviceId}`}>
								<td>{g.deviceId}</td>
								<td>
									<Time timestamp={g.lastDataTime} />
								</td>
								<td>{g.rssi}</td>
							</tr>
						))}
					</tbody>
				</table>
			</DeviceDetailsBlock>
		)
	}
}

// =============================================================================

class DeviceDetailsConfigurationBlock extends React.Component {
	constructor(props) {
		super(props)
		this.state = { busy: false, value: '', error: null }
	}
	async performWithPacket(p) {
		if (this.state.busy) return
		this.setState({ busy: true, error: null })

		const r = await API.sendPacketAndWaitResponse(p, this.props.device, {
			deviceId: this.props.device.id,
			command: RFCommand.CONFIG_REPORT
		})
		if (r) {
			this.setState({ value: JSON.stringify(r.config, null, 2), busy: false })
		} else {
			this.setState({ value: '', busy: false, error: 'Operation Failed!' })
		}
		return r
	}

	async read() {
		const p = new RFPacket()
		p.command = RFCommand.CONFIG_READ
		await this.performWithPacket(p)
	}

	async write() {
		const config = JSON.parse(this.state.value)

		const p = new RFPacket()
		p.command = RFCommand.CONFIG_WRITE
		p.data = Buffer.alloc(11)
		p.data.writeUInt8(config.operationMode, 0)
		p.data.writeUInt8(config.daliSpeed, 1)
		p.data.writeUInt8(config.idleBrightness, 2)
		p.data.writeUInt16LE(config.activeTime, 3)
		p.data.writeUInt8(config.activeBrightness, 5)
		p.data.writeUInt8(config.activeBrightnessN1, 6)
		p.data.writeUInt8(config.activeBrightnessN2, 7)
		p.data.writeUInt8(config.activeBrightnessN3, 8)
		p.data.writeUInt8(config.activeBrightnessN4, 9)
		p.data.writeUInt8(config.activeBrightnessN5, 10)
		await this.performWithPacket(p)
	}

	render() {
		const d = this.props.device
		return (
			<DeviceDetailsBlock title="Configuration">
				<TextArea
					style={{ height: '210px' }}
					disabled={this.state.busy}
					value={this.state.value}
					onChange={(event) => this.setState({ value: event.target.value })}
				/>
				{this.state.error && (
					<Callout icon="error" intent="danger">
						{this.state.error}
					</Callout>
				)}
				<div className="buttons">
					<Button fill text="Read" intent="success" disabled={this.state.busy} onClick={() => this.read()} />
					<Button fill text="Write" intent="danger" disabled={this.state.busy} onClick={() => this.write()} />
				</div>
			</DeviceDetailsBlock>
		)
	}
}

// =============================================================================

class DeviceDetailsStreetConfigurationBlock extends React.Component {
	constructor(props) {
		super(props)
		this.state = { busy: false, value: '', error: null }
	}

	async read() {
		if (this.state.busy) return
		this.setState({ busy: true, error: null })

		const p = new RFPacket()
		p.command = RFCommand.SCAN
		const r = await API.sendPacketAndWaitResponse(p, this.props.device, {
			deviceId: this.props.device.id,
			command: RFCommand.SCAN_REPORT
		})
		if (r) {
			let out = {
				operationMode: r.scanReport.operationMode,
				streetId: r.scanReport.streetId,
				poleId: r.scanReport.poleId
			}
			this.setState({ value: JSON.stringify(out, null, 2), busy: false })
		} else {
			this.setState({ value: null, busy: false, error: 'Operation Failed!' })
		}
	}

	async write() {
		if (this.state.busy) return
		this.setState({ busy: true, error: null })

		const config = JSON.parse(this.state.value)

		const p = new RFPacket()
		p.command = RFCommand.SET_IDENTITY
		p.destinationId = 0 // broadcast
		let buf = Buffer.alloc(13)
		buf.writeUInt32LE(this.props.device.id, 0)
		buf.writeUInt32LE(config.streetId, 4)
		buf.writeUInt32LE(config.poleId, 8)
		buf.writeUInt8(config.operationMode, 12)
		const serialBuf = Buffer.from(this.props.device.serialNr, 'hex')
		buf = Buffer.concat([serialBuf, buf])
		p.data = buf

		this.props.device.broadcast = true
		const r = await API.sendPacketAndWaitResponse(p, this.props.device, {
			deviceId: this.props.device.id,
			command: RFCommand.ACK
		})
		this.props.device.broadcast = false
		if (r) {
			this.setState({ busy: false })
		} else {
			this.setState({ busy: false, error: 'Operation Failed!' })
		}
	}

	render() {
		const d = this.props.device
		return (
			<DeviceDetailsBlock title="Street Configuration">
				<TextArea
					style={{ height: '110px' }}
					disabled={this.state.busy}
					value={this.state.value}
					onChange={(event) => this.setState({ value: event.target.value })}
				/>
				{this.state.error && (
					<Callout icon="error" intent="danger">
						{this.state.error}
					</Callout>
				)}
				<div className="buttons">
					<Button fill text="Read" intent="success" disabled={this.state.busy} onClick={() => this.read()} />
					<Button fill text="Write" intent="danger" disabled={this.state.busy} onClick={() => this.write()} />
				</div>
			</DeviceDetailsBlock>
		)
	}
}

// =============================================================================
