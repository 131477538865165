import './index.scss'

import Map from '../../Components/Map'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import API from '../../API'
import mapboxgl, { LngLatBounds } from 'mapbox-gl'
import { DateTime } from 'luxon'
import Lang from '../../Lang'
import Placeholder from '../../Components/Placeholder'
import { Button, FormGroup, InputGroup, Spinner } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import Actions from './Components/Actions'
import { AdminOnly, isDeviceGateway, valueToHexColor } from '../../Util'
import AccountSelector from '../../Components/AccountSelector'
import Time from '../../Components/Time'
import ProfilesDevicesSidebar from './Components/ProfilesDevicesSidebar'
import { NavLink } from 'react-router-dom'
import ReplaceDialog from './Components/ReplaceDialog'

function createMarkerElementForDevice(dev) {
	const markerElement = document.createElement('div')
	markerElement.className = 'Marker DeviceMarker'
	markerElement.style.backgroundColor = 'gray'

	const iconElement = document.createElement('i')
	const deviceIcon = dev.customIcon ?? 'lightbulb'
	iconElement.className = 'fa-fw fa-duotone fa-' + deviceIcon
	markerElement.appendChild(iconElement)

	const labelElement = document.createElement('div')
	labelElement.className = 'label'
	labelElement.innerText = dev.id
	markerElement.appendChild(labelElement)

	if (isDeviceGateway(dev)) {
		const gatewayIcon = document.createElement('i')
		gatewayIcon.className = 'gatewayIcon fa-fw fa-duotone fa-wifi'
		markerElement.appendChild(gatewayIcon)
	}

	return markerElement
}

export default function InspectorPage() {
	const mapRef = useRef(null)

	useEffect(() => {
		document.title = 'Inspector - Idea Lights'

		let meta = document.querySelector('meta[name="viewport"]')
		if (!meta) {
			meta = document.createElement('meta')
			meta.name = 'viewport'
			document.head.appendChild(meta)
		}
		meta.content = 'width=device-width, initial-scale=1'

		return () => {
			let meta = document.querySelector('meta[name="viewport"]')
			if (meta) {
				meta.remove()
			}
		}
	}, [])

	const [devices, setDevices] = useState(null)
	const [profiles, setProfiles] = useState(null)

	const [sidebarOpen, setSidebarOpen] = useState(false)
	const [replaceDialogOpen, setReplaceDialogOpen] = useState(false)

	const [iconColors, setIconColors] = useState('lighting-profile')

	const [editingDevice, setEditingDevice] = useState({
		id: -1,
		title: '',
		latitude: 0,
		longitude: 0,
		lightingProfileId: -1
	})
	const [saving, setSaving] = useState(0)
	const [unsavedChanges, setUnsavedChanges] = useState(false)
	async function handleSave() {
		if (!activeDevice) return

		const newDeviceData = { ...editingDevice }

		if (newDeviceData.latitude == '') newDeviceData.latitude = null
		if (newDeviceData.longitude == '') newDeviceData.longitude = null

		setSaving(1)
		const r1 = await API.call('Inspector.Device.Update', {
			id: activeDevice.id,
			...newDeviceData,
			lightingProfileId: activeDevice.lightingProfileId // because we want to ignore for Inspector.Device.Update
		})

		if (!r1 || !r1.success) {
			console.error('Failed to update device', r1)
			return
		}

		// set profile to device
		if (activeDevice.lightingProfileId != newDeviceData.lightingProfileId && newDeviceData.lightingProfileId != -1) {
			const r2 = await API.call('LightingProfile.AddDevice', {
				deviceId: activeDevice.id,
				profileId: newDeviceData.lightingProfileId
			})

			if (!r2 || !r2.success) {
				console.error('Failed to add device to profile', r2)
				return
			}
		}

		// setDevices((dev) => {
		// 	return dev.map((d) => {
		// 		if (d.id == activeDevice.id) {
		// 			d.marker.setLngLat([d.longitude, d.latitude])
		// 			return { ...d, ...editingDevice }
		// 		}

		// 		return d
		// 	})
		// })

		// setProfiles((p) => {
		// 	return p.map((p) => {
		// 		if (p.id == editingDevice.lightingProfileId) {
		// 			return { ...p, deviceIds: p.deviceIds.concat(activeDevice.id) }
		// 		}

		// 		if (p.id == activeDevice.lightingProfileId) {
		// 			return { ...p, deviceIds: p.deviceIds.filter((id) => id != activeDevice.id) }
		// 		}

		// 		return p
		// 	})
		// })

		await reload()

		setSaving(2)
		setTimeout(() => setSaving(0), 1000)

		setUnsavedChanges(false)
	}

	const [selectingFromPin, setSelectingFromPin] = useState(false)
	const [activeDevice, setActiveDevice] = useState(null)

	useEffect(() => {
		if (!devices || !profiles) return

		const now = new Date().getTime() / 1000

		for (let d of devices) {
			if (!d.marker) continue

			const markerElement = d.marker.getElement()
			markerElement.style.backgroundColor = 'gray'

			if (iconColors == 'lighting-profile') {
				for (let p of profiles) {
					if (p.deviceIds.indexOf(d.id) != -1) {
						markerElement.style.backgroundColor = p.color
					}
				}
			} else if (iconColors == 'device-status') {
				const diff = now - d.lastDataTime
				if (diff < 60 * 15) {
					// 15 minutes
					markerElement.style.backgroundColor = '#32A467'
				} else if (diff < 60 * 60 * 24) {
					// 24 hours
					markerElement.style.backgroundColor = '#EC9A3C'
				} else {
					markerElement.style.backgroundColor = '#CD4246'
				}
			} else if (iconColors == 'report-health') {
				const reportCount = d.reportHealth
				const healthPercentage = reportCount / 30
				markerElement.style.backgroundColor = valueToHexColor(healthPercentage)
			} else if (iconColors == 'operative') {
				const diff = now - d.lastDataTime
				if (diff >= 60 * 60) {
					// No data for an hour or more -> gray
					markerElement.style.backgroundColor = '#CCCCCC'
				} else {
					const d4i = d.lastCommands.find((c) => c.commandName == 'D4I')
					if (d4i?.payload.ledCurrent > 0) {
						// Led current positive -> green
						markerElement.style.backgroundColor = '#32A467'
					} else {
						// Led current zero, or no D4I command -> yellow
						markerElement.style.backgroundColor = '#CCCC66'
					}
				}
			}
		}
	}, [setDevices, setProfiles, devices, profiles, iconColors])

	function flyToDevice(device, fromMap = false) {
		const coords = [device.longitude, device.latitude]
		if (!coords.every((c) => c)) return

		mapRef.current?.flyTo({
			center: coords,
			...(!fromMap && { zoom: 18 }),
			essential: true
		})
	}

	function zoomDevices(devices) {
		const zoomBounds = new LngLatBounds()
		for (let d of devices) {
			if (!d.latitude || !d.longitude) continue

			zoomBounds.extend([d.longitude, d.latitude])
		}

		if (Object.values(zoomBounds).length === 0) return

		mapRef.current?.resize()
		mapRef.current?.fitBounds(zoomBounds, { padding: 100, duration: 250 })
	}

	const onTrackUserLocationStart = useCallback(
		(coords) => {
			setDevices((devices) => {
				zoomDevices([...devices, { latitude: coords[0], longitude: coords[1] }])
				return devices
			})
		},
		[devices]
	)

	const centerPinRef = useRef(null)

	const onMapMove = useCallback(() => {
		const center = mapRef.current?.getCenter()
		centerPinRef.current.setLngLat(center)
	}, [])

	function startSelectingFromPin() {
		setSelectingFromPin(true)

		const centerPinEl = document.createElement('div')
		centerPinEl.className = 'center-pin'
		centerPinRef.current = new mapboxgl.Marker({ element: centerPinEl })
			.setLngLat(mapRef.current?.getCenter())
			.addTo(mapRef.current)
		mapRef.current?.on('move', onMapMove)
	}

	function stopSelectingFromPin(cancel) {
		setSelectingFromPin(false)

		if (!cancel) {
			const { lat, lng } = centerPinRef.current.getLngLat()

			setUnsavedChanges(true)
			setEditingDevice((o) => {
				return { ...o, latitude: lat, longitude: lng }
			})
		} else {
			if (activeDevice) {
				flyToDevice(activeDevice)
			}
		}

		centerPinRef.current.remove()
		mapRef.current?.off('move', onMapMove)
	}

	function showActiveDeviceOnMap() {
		setActiveDevice((dev) => {
			if (dev) {
				flyToDevice(dev)
			}

			return null
		})
	}

	const previousEditingDeviceRef = useRef(null)

	useEffect(() => {
		if (editingDevice.id != -1) {
			const oldEditingDevice = devices.find((d) => d.id == previousEditingDeviceRef.current?.id)
			const newEditingDevice = devices.find((d) => d.id == editingDevice.id)

			if (oldEditingDevice) {
				oldEditingDevice.marker?.getElement().classList.remove('selected')
			}

			if (newEditingDevice) {
				newEditingDevice.marker?.getElement().classList.add('selected')
			}
		}
	}, [editingDevice, devices])

	const selectDevice = useCallback(
		(device, fromMap = false) => {
			setActiveDevice(device)
			setEditingDevice((currentEditingDevice) => {
				previousEditingDeviceRef.current = currentEditingDevice

				const ret = {
					...{
						id: device.id,
						title: device.title ?? '',
						latitude: device.latitude ?? '',
						longitude: device.longitude ?? '',
						lightingProfileId: device.lightingProfileId ?? -1
					}
				}

				return ret
			})

			setUnsavedChanges(false)

			flyToDevice(device, fromMap)

			return true
		},
		[setActiveDevice, setEditingDevice, setUnsavedChanges, flyToDevice, setDevices]
	)

	const reload = useCallback(async () => {
		const [_devices, _profiles] = await Promise.all([API.getDevices(), API.getProfiles()])
		if (_devices.error) return

		setProfiles(_profiles)

		console.log(_devices.devices)

		if (devices) {
			for (const d of devices) {
				d.marker?.remove()
			}
		} else {
			zoomDevices(_devices.devices)
		}

		for (const d of _devices.devices) {
			if (!d.latitude || !d.longitude) continue

			const deviceCoord = [d.longitude, d.latitude]

			const markerElement = createMarkerElementForDevice(d)
			markerElement.addEventListener('click', (e) => selectDevice(d, true))

			const marker = new mapboxgl.Marker({ element: markerElement }).setLngLat(deviceCoord).addTo(mapRef.current)
			d.marker = marker
		}

		setDevices(_devices.devices)
	}, [setDevices, setProfiles, selectDevice, devices, profiles])

	const middle = (
		<div className="details" data-inactive={activeDevice == null || selectingFromPin}>
			<div className="close-btn" onClick={() => setActiveDevice(null)}>
				<i className="fa-solid fa-xmark" style={{ fontSize: '2rem' }}></i>
			</div>

			{activeDevice != null && (
				<div className="details__body">
					<div className="device-id">
						Device ID: <strong>{activeDevice.id}</strong>
					</div>

					<FormGroup label={<strong>Title</strong>} labelFor="device-title-input" style={{ margin: 0 }}>
						<InputGroup
							id="device-title-input"
							placeholder="Title"
							value={editingDevice.title}
							onChange={(e) => {
								setUnsavedChanges(true)
								setEditingDevice((o) => ({ ...o, title: e.target.value }))
							}}
						/>
					</FormGroup>

					<div className="location-group">
						<strong>Location</strong>
						<div>
							<Button icon={'map'} onClick={showActiveDeviceOnMap}>
								Show on map
							</Button>

							<Button icon={'pin'} onClick={startSelectingFromPin}>
								Change GPS
							</Button>

							<div className="coordinates">
								<FormGroup labelFor="device-lat-input" style={{ margin: 0 }}>
									<InputGroup
										id="device-lat-input"
										placeholder="Latitude"
										value={editingDevice.latitude}
										onChange={(e) => {
											setUnsavedChanges(true)
											setEditingDevice((o) => ({ ...o, latitude: e.target.value }))
										}}
									/>
								</FormGroup>

								<FormGroup labelFor="device-lng-input" style={{ margin: 0 }}>
									<InputGroup
										id="device-lng-input"
										placeholder="Longitude"
										value={editingDevice.longitude}
										onChange={(e) => {
											setUnsavedChanges(true)
											setEditingDevice((o) => ({ ...o, longitude: e.target.value }))
										}}
									/>
								</FormGroup>
							</div>
						</div>
					</div>

					<FormGroup label={<strong>Lighting profile</strong>} labelFor="device-lighting-profile" style={{ margin: 0 }}>
						<div className="bp5-html-select" style={{ width: '100%' }}>
							<select
								style={{ fontSize: 'large' }}
								onChange={(e) => {
									setUnsavedChanges(true)
									setEditingDevice((o) => ({ ...o, lightingProfileId: e.target.value === -1 ? null : parseInt(e.target.value) }))
								}}
								value={editingDevice.lightingProfileId}>
								<option value={-1}>None</option>
								{profiles.map((p) => (
									<option value={p.id} key={`lp-option${p.id}`}>
										{p.title}
									</option>
								))}
							</select>
							<span className="bp5-icon bp5-icon-double-caret-vertical" />
						</div>
					</FormGroup>

					<div className="info-block">
						<strong>{Lang.get('Last Heard')}: </strong>
						<span>
							<Time timestamp={activeDevice.lastDataTime} />
						</span>

						{!activeDevice.isConfigured && (
							<Tooltip2 content={Lang.get('Installing configuration...')} position="bottom">
								<i className={`fa-fw fa-duotone fa-loader`} style={{ color: 'orange' }} />
							</Tooltip2>
						)}

						{(() => {
							let lastSeenError = null
							if (!activeDevice.lastDataTime) {
								lastSeenError = Lang.get('No signal')
							} else {
								const lastSeen = DateTime.fromSeconds(activeDevice.lastDataTime)
								const diff = DateTime.now().diff(lastSeen, ['months', 'weeks', 'days', 'hours', 'minutes']).toObject()
								if (diff.months > 0) {
									lastSeenError = Lang.get('Last signal %s months ago', diff.months)
								} else if (diff.weeks > 0) {
									lastSeenError = Lang.get('Last signal %s weeks ago', diff.weeks)
								} else if (diff.days > 0) {
									lastSeenError = Lang.get('Last signal %s days ago', diff.days)
								}
							}

							if (lastSeenError) {
								return (
									<Tooltip2 content={lastSeenError} position="bottom">
										<i className={`fa-fw fa-duotone fa-exclamation-triangle`} style={{ color: 'red' }} />
									</Tooltip2>
								)
							}
						})()}
					</div>

					<AdminOnly>
						<button className="bp5-button" onClick={() => setReplaceDialogOpen(true)}>
							Replace / swap
						</button>
					</AdminOnly>
				</div>
			)}

			{(unsavedChanges || saving == 2) && (
				<Button
					intent="primary"
					className="save-btn"
					onClick={handleSave}
					disabled={saving == 1 || saving == 2}
					icon={saving == 1 ? <Spinner className="spinner-icon white" /> : saving == 2 ? 'tick' : 'blank'}>
					Save
				</Button>
			)}
		</div>
	)

	const bottom = (
		<div className={`enter ${activeDevice && 'panel'}`}>
			{(() => {
				if (selectingFromPin) {
					return (
						<div className="selecting-pin-btn">
							<Button intent="danger" icon={'stop'} onClick={() => stopSelectingFromPin(true)}>
								Cancel
							</Button>
							<Button intent="primary" icon={'pin'} onClick={() => stopSelectingFromPin(false)}>
								Select
							</Button>
						</div>
					)
				}

				if (!activeDevice) {
					return (
						<button className="bp5-button inspector-button" onClick={() => setSidebarOpen(true)}>
							<i className="fa-solid fa-search"></i>
						</button>
					)
				}

				return <Actions device={activeDevice} />
			})()}
		</div>
	)

	function onMapViewStateChange(newState) {
		if (newState.iconColors) {
			setIconColors(newState.iconColors)
		}
	}

	return (
		<>
			<Placeholder overlay overlayVisible={devices === null} loading label={Lang.get('Loading devices') + ' ...'} />
			<main className="technician-page">
				<div className="overlay">
					{!activeDevice && (
						<div className="top-left">
							<NavLink to={'/'} style={{ pointerEvents: 'all' }}>
								<button className="bp5-button inspector-button home-btn" onClick={() => setSidebarOpen(true)}>
									<i className="fa-solid fa-house"></i>
								</button>
							</NavLink>
							<div className="account">
								<AccountSelector />
							</div>
						</div>
					)}

					{middle}

					{bottom}
				</div>

				<Map
					onReady={(ref) => {
						mapRef.current = ref.current
						reload()
					}}
					onViewStateChange={(newState) => onMapViewStateChange(newState)}
					onTrackUserLocationStart={onTrackUserLocationStart}
					onClick={() => setSidebarOpen(false)}
				/>
			</main>

			<ProfilesDevicesSidebar
				open={sidebarOpen}
				onOpenChange={setSidebarOpen}
				devices={devices}
				profiles={profiles}
				onDeviceSelect={async (dev) => {
					setSidebarOpen(false)
					selectDevice(dev)
				}}
			/>

			<ReplaceDialog
				open={replaceDialogOpen}
				setOpen={setReplaceDialogOpen}
				selectedDevice={activeDevice}
				onSuccess={() => setActiveDevice(null)}
				devices={devices}
				reload={reload}
			/>
		</>
	)
}
