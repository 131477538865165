import './index.scss'

import React, { useEffect, useState } from 'react'
import API from '../../../../API'
import Lang from '../../../../Lang'
import { pick } from '../../../../Util'
import DeviceChoice from './DeviceChoice'
import { Button, FormGroup, InputGroup, Spinner } from '@blueprintjs/core'
import ConfirmPopover from '../../../../Components/ConfirmPopover'

import ProfileV1Editor from './ProfileV1Editor'
import ProfileV2Editor from './ProfileV2Editor'
import { AppToaster } from '../../../../Components/App'

export default function ProfileDetails({ profile, selectProfile, reload }) {
	const [editProfile, setEditProfile] = useState({ version: 1, ...profile })
	useEffect(() => {
		setEditProfile({ version: 1, ...profile })
	}, [profile])

	const [savingProfile, setSavingProfile] = useState(false)

	function setProfileValue(key, value) {
		setEditProfile((currentProfile) => ({ ...currentProfile, [key]: value }))
	}

	async function save() {
		setSavingProfile(true)

		const profileData = structuredClone(editProfile)

		if (profileData.version == 2) {
			// merge fake blocks into real blocks
			profileData.conditionBlocks.forEach((block, idx) => {
				if (block.conditions.daylight) {
					block.brightnessLevels.forEach((period, jdx) => {
						const prev = block.brightnessLevels[jdx - 1]

						// merge previous fake block into current real block
						if (prev?.fake && !period.fake) {
							period.start = prev.start
							period.duration += prev.duration
							block.brightnessLevels.splice(jdx - 1, 1)
						}
					})

					block.brightnessLevels.forEach((period, jdx) => {
						const next = block.brightnessLevels[jdx + 1]

						if (next?.fake && !period.fake) {
							period.duration += next.duration
							block.brightnessLevels.splice(jdx + 1, 1)
						}
					})
				}

				if (block.conditions.nightlight) {
					block.brightnessLevels.forEach((period, jdx) => {
						const next = block.brightnessLevels[jdx + 1]
						const prev = block.brightnessLevels[jdx - 1]

						if (next?.fake && !period.fake) {
							period.duration += 43200 - next.start
						}

						if (prev?.fake && !period.fake) {
							const curStart = period.start
							period.start = 43200
							period.duration += curStart - 43200

							block.brightnessLevels.splice(jdx - 1, 1)
						}
					})
				}

				block.brightnessLevels.forEach((_, idx) => {
					delete block.brightnessLevels[idx].fake
				})
			})
		}

		if (editProfile.id == 0) {
			delete profileData.id
			await API.call('LightingProfile.Create', profileData)
		} else {
			await API.call('LightingProfile.Update', profileData)
		}
		;(await AppToaster).show({ message: 'Profile saved!', intent: 'success' })

		setSavingProfile(false)

		selectProfile(null)
		reload(false)
	}

	return (
		<div className="ProfileDetails">
			<div className="closeButton">
				<Button icon="cross" minimal onClick={() => selectProfile(null)} />
			</div>

			<div className="content">
				<FormGroup label={Lang.get('Profile Title')}>
					<InputGroup value={editProfile.title} onChange={(e) => setProfileValue('title', e.target.value)} />
				</FormGroup>

				{editProfile.version == 1 && (
					<ProfileV1Editor profile={editProfile} onChange={(vals) => setEditProfile((ep) => ({ ...ep, ...vals }))} />
				)}
				{editProfile.version == 2 && (
					<ProfileV2Editor profile={editProfile} onChange={(vals) => setEditProfile((ep) => ({ ...ep, ...vals }))} />
				)}

				<DeviceChoice
					onChange={(newDeviceIds) => setEditProfile((p) => ({ ...p, deviceIds: newDeviceIds }))}
					deviceIds={editProfile.deviceIds}
				/>
			</div>

			<div className="options">
				{editProfile.id != 0 && (
					<ConfirmPopover
						message={Lang.get('Are you sure?')}
						confirmTitle={Lang.get('Confirm')}
						// onConfirm={() => this.deleteProfile()}
					>
						<Button text={Lang.get('Delete Profile')} icon="trash" intent="danger" />
					</ConfirmPopover>
				)}
				<div />
				<Button
					text={editProfile.id == 0 ? Lang.get('Create Profile') : Lang.get('Save')}
					icon={savingProfile ? <Spinner className="spinner-icon white" /> : 'floppy-disk'}
					intent="primary"
					onClick={() => save()}
					disabled={savingProfile}
				/>
			</div>
		</div>
	)
}
