import './ReplaceDialog.scss'

import { useState } from 'react'
import { Dialog, DialogBody, FormGroup } from '@blueprintjs/core'
import API from '../../../API'

export default function ReplaceDialog({ open, setOpen, selectedDevice, onSuccess, devices, reload }) {
	const [busy, setBusy] = useState(false)
	const [newDeviceId, setNewDeviceId] = useState(0)
	const [type, setType] = useState('replace')

	async function handleReplace() {
		const oldDeviceId = selectedDevice.id

		setBusy(true)

		const res = await API.call('Device.Replace', {
			oldDeviceId,
			newDeviceId,
			type
		})

		console.log(res)

		if (res.success) {
			await reload()
			setOpen(false)
			onSuccess()
		}

		setBusy(false)
	}

	devices?.sort((a, b) => a.id - b.id)

	return (
		<Dialog isOpen={open} onClose={() => setOpen(false)} className="replace-dialog">
			<DialogBody className="body">
				<p className="header">Replace / swap device {selectedDevice?.id}</p>

				<FormGroup label={<strong>Method</strong>} labelFor="new-replace-device" style={{ margin: 0 }}>
					<div className="bp5-html-select" style={{ width: '100%' }}>
						<select style={{ fontSize: 'large' }} onChange={(e) => setType(e.target.value)} value={type}>
							<option value={'replace'}>Replace</option>
							<option value={'swap'}>Swap</option>
						</select>
						<span className="bp5-icon bp5-icon-double-caret-vertical" />
					</div>
				</FormGroup>

				<FormGroup label={<strong>New device</strong>} labelFor="new-replace-device" style={{ margin: 0 }}>
					<div className="bp5-html-select" style={{ width: '100%' }}>
						<select style={{ fontSize: 'large' }} onChange={(e) => setNewDeviceId(e.target.value)} value={newDeviceId}>
							<option value={0}>-</option>
							{devices?.map((device) =>
								device.id === selectedDevice?.id ? null : (
									<option key={`rddi${device.id}`} value={device.id}>
										{device.id}
									</option>
								)
							)}
						</select>
						<span className="bp5-icon bp5-icon-double-caret-vertical" />
					</div>
				</FormGroup>

				<button className="bp5-button replace-btn" onClick={handleReplace} disabled={newDeviceId == 0 || busy}>
					{type}
				</button>
			</DialogBody>
		</Dialog>
	)
}
